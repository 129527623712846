/**
 * Dependencies
 */

import React from 'react'
import { Redirect } from 'react-router-dom'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function Poll(props) {
  async function deletePoll(e, poll_id) {
    e.preventDefault()

    const yes = window.confirm('Are you sure? This permanently deletes this Poll.')

    if (yes) {
      await firebase.db.collection('polls').doc(poll_id).delete()
      window.location.reload()
    }
  }

  function updateEvents(index) {
    const new_events = props.events.map(event => {
      if (props.poll.event_id === event.id) {
        const new_polls = event.polls.map(poll => {
          if (poll.id === props.poll.id) {
            // Increment answer
            poll.counts[index] += 1
          }
          return poll
        })

        event.polls = new_polls
      }
      return event
    })

    props.setEvents(new_events)
  }

  async function incrementAnswerA(e, poll_id) {
    e.preventDefault()
    const new_counts = [props.poll.counts[0]+1, props.poll.counts[1], props.poll.counts[2], props.poll.counts[3]]
    await firebase.db.collection('polls').doc(poll_id).update({ counts: new_counts })
    updateEvents(0)
  }
  async function incrementAnswerB(e, poll_id) {
    e.preventDefault()
    const new_counts = [props.poll.counts[0], props.poll.counts[1]+1, props.poll.counts[2], props.poll.counts[3]]
    await firebase.db.collection('polls').doc(poll_id).update({ counts: new_counts })
    updateEvents(1)
  }
  async function incrementAnswerC(e, poll_id) {
    e.preventDefault()
    const new_counts = [props.poll.counts[0], props.poll.counts[1], props.poll.counts[2]+1, props.poll.counts[3]]
    await firebase.db.collection('polls').doc(poll_id).update({ counts: new_counts })
    updateEvents(2)
  }
  async function incrementAnswerD(e, poll_id) {
    e.preventDefault()
    const new_counts = [props.poll.counts[0], props.poll.counts[1], props.poll.counts[2], props.poll.counts[3]+1]
    await firebase.db.collection('polls').doc(poll_id).update({ counts: new_counts })
    updateEvents(3)
  }

  return (
    <div className="row">
      <div className="col-12">

        <div className="row p-3">
          <div className="col-12 p-3 border bg-light shadow-sm">
            <p><strong>{props.poll.description}</strong></p>
            <hr/>
            <ul className="list-unstyled">
              {props.poll.answers[0].length > 0 ?
                <li>
                  {props.poll.answers[0]}<br/>
                  <button onClick={e => incrementAnswerA(e, props.poll.id)}
                          className="btn btn-sm btn-link text-success">
                    <i className="fa fa-plus"></i>
                  </button>

                  <span className="bg-secondary mr-1" style={{"height": "10px", "width": `${Math.round(props.poll.counts[0] / (props.poll.counts[0] + props.poll.counts[1] + props.poll.counts[2] + props.poll.counts[3]) * 80)}%`, "display": "inline-block"}}></span>
                  {props.poll.counts[0]}
                </li> : null
              }

              {props.poll.answers[1].length > 0 ?
                <li>
                  {props.poll.answers[1]}<br/>
                  <button onClick={e => incrementAnswerB(e, props.poll.id)}
                          className="btn btn-sm btn-link text-success">
                    <i className="fa fa-plus"></i>
                  </button>

                  <span className="bg-secondary mr-1" style={{"height": "10px", "width": `${Math.round(props.poll.counts[1] / (props.poll.counts[0] + props.poll.counts[1] + props.poll.counts[2] + props.poll.counts[3]) * 80)}%`, "display": "inline-block"}}></span>
                  {props.poll.counts[1]}
                </li> : null
              }

              {props.poll.answers[2].length > 0 ?
                <li>
                  {props.poll.answers[2]}<br/>
                  <button onClick={e => incrementAnswerC(e, props.poll.id)}
                          className="btn btn-sm btn-link text-success">
                    <i className="fa fa-plus"></i>
                  </button>

                  <span className="bg-secondary mr-1" style={{"height": "10px", "width": `${Math.round(props.poll.counts[2] / (props.poll.counts[0] + props.poll.counts[1] + props.poll.counts[2] + props.poll.counts[3]) * 80)}%`, "display": "inline-block"}}></span>
                  {props.poll.counts[2]}
                </li> : null
              }

              {props.poll.answers[3].length > 0 ?
                <li>
                  {props.poll.answers[3]}<br/>
                  <button onClick={e => incrementAnswerD(e, props.poll.id)}
                          className="btn btn-sm btn-link text-success">
                    <i className="fa fa-plus"></i>
                  </button>

                  <span className="bg-secondary mr-1" style={{"height": "10px", "width": `${Math.round(props.poll.counts[3] / (props.poll.counts[0] + props.poll.counts[1] + props.poll.counts[2] + props.poll.counts[3]) * 80)}%`, "display": "inline-block"}}></span>
                  {props.poll.counts[3]}
                </li> : null
              }
            </ul>

            {props.isInvitedTrip ? null :
              <button onClick={e => deletePoll(e, props.poll.id)}
                      className="btn btn-sm btn-link text-danger float-right">
                <i className="fa fa-trash"></i>
              </button>
            }
          </div>
        </div>

      </div>
    </div>
  )
}

/**
 * Export component
 */

export default Poll
