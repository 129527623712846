/**
 * Dependencies
 */

import React, { useState } from 'react'

/**
 * Define component
 */

function AnswersField(props) {
  return (
    <div className="form-group my-2">
      <label htmlFor={`input-url-${props.index}`}>Answer #{props.index}</label>
      <input id={`input-url-${props.index}`} name={`answer_${props.index}`}
             type="text" className="form-control"
             value={props.value}
             onChange={e => props.setValue(e.target.value)}></input>
    </div>
  )
}

/**
 * Export component
 */

export default AnswersField
