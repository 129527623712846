/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Payments() {
  return (
    <>
      <section id="payments" className="my-5">
        <h4 className="my-4">
          Payments
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            You are responsible for payment. We are responsible for billing you accurately.
          </div>
        </div>

        <p id="payments-p1">
          Nomadic Planner will provide the Services to you at the rates and for the fees ("Fees")
          described in the Fee Schedule at
          <a href="https://www.nomadicplanner.com/pricing" target="_blank" rel="noopener noreferrer">www.nomadicplanner.com/pricing</a>,
          linked here and incorporated into this Agreement. We may revise the Fees at
          any time upon 30 days' Notice to you.
        </p>

        <p id="payments-p5">
          Upgrade subscriptions are set to auto-renew and Nomadic Planner may automatically
          charge a User for such renewal on or after the renewal date unless the User
          has cancelled the Service or Upgrade prior to the renewal date. A
          Upgrade subscription can be cancelled at any time in the Settings section
          of the Site for any reason.
        </p>

        <p id="payments-p7">
          If you do not understand the Fee Schedule or you have a question about Fees,
          please ask for support at
          <a href="mailto:support@nomadicplanner.com">support@nomadicplanner.com</a>
          before using the Services.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Payments
