/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DeactivateAccountStyle } from './styles/index'
import { HomeStyle } from '../users/styles/index'
import { UserContext } from '../../contexts/index'
import { BackToHomeNavbar, BackToLink } from '../../components/Home/index'
import firebase from '../../helpers/firebase'
import uuid from 'uuid'

/**
 * Define view
 */

function DeactivateAccount(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    setTimeout(() => {
      if (userContext) setIsLoading(false)
    }, 600)
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    try {
      await firebase.db.collection('deactivations').doc(uuid.v4()).set({
        user_uid: userContext.uid,
        user_email: userContext.email,
        feedback: feedback,
      })

      localStorage.removeItem('user')
      firebase.logout()

      return <Redirect to="/users/login" />
    } catch(err) {
      console.error(err)
    }
  }

  return (
    <DeactivateAccountStyle>
      <HomeStyle>
        <header className="bg-light">
          <BackToHomeNavbar />
        </header>

        {isLoading ? <LinearProgress /> : null}
      </HomeStyle>

      <main className="bg-white shadow py-4">
        <div className="container">
          <section>
            <div className="row border-bottom mt-3 mb-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-8 col-sm-9">
                    <h3 id="h3-settings">Deactivate Account</h3>
                  </div>

                  <div className="col-4 col-sm-3 text-right d-flex justify-content-end align-items-end mb-2">
                    <Link to="/users/settings" className="text-muted align-bottom">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="section-form">
            <p>
              We're sorry to see you go. This action begins the termination of your account. Contact us at <a href="mailto:support@nomadicplanner.com">support@nomadicplanner.com</a> if there's anything we can do to change your mind.
            </p>

            <div className="row">
              <div className="col-12 col-sm-9 col-md-7 col-lg-5">
                <form onSubmit={handleOnSubmit}>
                  <div className="form-group">
                    <label htmlFor="input-email" className="w-100">Account to disable</label>
                    <input id="input-email"
                           className="form-control"
                           name="user_email" type="email"
                           value={userContext.email} disabled></input>
                  </div>

                  <div className="form-group">
                    <label htmlFor="input-reason" className="w-100">Tell us why you're disabling your account (optional)</label>
                    <textarea id="input-reason" className="form-control" name="reason" rows="4" maxLength="5000"
                              onChange={e => setFeedback(e.target.value)}
                              value={feedback}></textarea>
                  </div>

                  <button type="submit" className="btn btn-secondary">
                    Deactivate your account
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </main>
    </DeactivateAccountStyle>
  )
}

/**
 * Export view
 */

export default DeactivateAccount
