/**
 * Dependencies
 */

import React, { useState } from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

/**
 * Define component
 */

function MapSearchModal(props) {
  const [address, setAddress] = useState('')

  function handleClickOpen() {
    props.setOpenModal(true)
  }

  function handleClose() {
    props.setOpenModal(false)
  }

  function getLocation(event) {
    event.preventDefault()

    const formatted = event.target.elements.address.value.replace(/ /g, "+")

    axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${formatted}&key=AIzaSyCBPvB7nBDPXu9ztjYg3BrYDt1skI2R01g`)
      .then(res => {
        document.activeElement.value = ''
        document.activeElement.blur()
        props.setOpenModal(false)

        if (res.data && res.data.results && res.data.results.length > 0) {
          let point = res.data.results[0]

          if (point.geometry.location.lat && point.geometry.location.lng) {
            props.map.flyTo([point.geometry.location.lat, point.geometry.location.lng], 11)

            props.setCoordLat(point.geometry.location.lat)
            props.setCoordLat(point.geometry.location.lng)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Dialog open={props.openModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogContent>
        <form onSubmit={getLocation}>
          <TextField
            autoFocus
            margin="dense"
            label="Address"
            className="text-field"
            type="text"
            name="address"
            onChange={e => setAddress(e.target.value)}
            value={address}
            fullWidth={true}
          />

          <Button type="submit" color="primary">
            Search
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

/**
 * Export component
 */

export default MapSearchModal
