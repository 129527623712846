/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function WarrantyDisclaimer() {
  return (
    <>
      <section id="warranty-disclaimer" className="my-5">
        <h4 className="my-3">
          Warranty Disclaimer
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We work hard to provide you with great Services, but we can't guarantee
            everything will always work perfectly. This Site is presented as-is, without
            warranties.
          </div>
        </div>

        <p id="warranty-disclaimer-p1">
          You use our Services solely at your own risk. They are provided to you
          "as is" and "as available" and without warranty of any kind, express or implied.
        </p>

        <p id="warranty-disclaimer-p2">
          NOMADIC PLANNER SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE,
          AND ANY WARRANTIES IMPLIED BY ANY COURSE OF DEALING, COURSE OF PERFORMANCE,
          OR USAGE OF TRADE. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY
          YOU FROM NOMADIC PLANNER SHALL CREATE ANY WARRANTY.
        </p>

        <p id="warranty-disclaimer-p3">
          No data, documentation or any other information provided by Nomadic Planner or
          obtained by you from or through the Service creates or implies any warranty
          from Nomadic Planner to you.
        </p>

        <p id="warranty-disclaimer-p4">
          Nomadic Planner does not warrant that the Service will be uninterrupted, timely,
          secure, or error-free.
        </p>

        <p id="warranty-disclaimer-p5">
          Nomadic Planner does not warrant that the quality of any products, services,
          information, or other materials purchased or obtained by you through
          the Service will meet your expectations, or that any errors in the
          Service will be corrected.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default WarrantyDisclaimer
