/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function InfoProtection() {
  return (
    <>
      <section id="info-protection" className="my-5">
        <h4 className="my-4">
          How We Protect Your Information
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We use organizational, technical, and administrative measures to protect your
            information.
          </div>
        </div>

        <p id="info-protection-p1">
          Nomadic Planner takes all measures reasonably necessary to protect Users' personal
          information from unauthorized access, alteration, or destruction; maintain
          data accuracy; and help ensure the appropriate use of Users' personal
          information. Unfortunately, no data transmission or storage system can be
          guaranteed to be 100% secure. If you have reason to believe that your
          interaction with us is no longer secure (for example, if you feel that the
          security of your account has been compromised), please contact us immediately
          at <a href="mailto:privacy@nomadicplanner.com">privacy@nomadicplanner.com</a>.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default InfoProtection
