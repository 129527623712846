/**
 * Dependencies
 */

import React from 'react'
import { GettingStartedStyle } from './styles/index'

/**
 * Define view
 */

function GettingStarted() {
  return (
    <GettingStartedStyle>
      <div className="row mx-auto">
        <div className="col-12">
          GettingStarted
        </div>
      </div>
    </GettingStartedStyle>
  )
}

/**
 * Export view
 */

export default GettingStarted
