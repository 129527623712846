/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function UsageOfInfo() {
  return (
    <>
      <section id="usage-of-info" className="my-5">
        <h4 className="my-4">
          How We Use This Information
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We use collected information strictly for providing, protecting, improving,
            and promoting our Services.
          </div>
        </div>

        <p id="usage-of-info-p1">
          Nomadic Planner is not in the business of selling your information. We consider this
          information to be a vital part of our relationship with you.
        </p>

        <p id="usage-of-info-p2">
          We use the information we collect for the following purposes:
        </p>

        <ul>
          <li id="usage-of-info-li1">
            To keep your account secure.
          </li>
          <li id="usage-of-info-li2">
            To enable us to provide you with our Services, and to improve, promote,
            and protect our Services.
          </li>
          <li id="usage-of-info-li3">
            To set up and administer your account, contact you, and customize your
            experience on Nomadic Planner.
          </li>
          <li id="usage-of-info-li4">
            To track use of the Services so that we can improve Nomadic Planner.
          </li>
        </ul>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default UsageOfInfo
