/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { UsersCardStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import { EventMap } from '../Events/index'

/**
 * Define component
 */

function NotificationList(props) {
  const userContext = useContext(UserContext)
  const [invites, setInvites] = useState(props.invites)
  const [tripInvites, setTripInvites] = useState(props.tripInvites)

  if ((!invites && !tripInvites) || (invites.length === 0 && tripInvites.length === 0)) {
    return <h3 className="text-center mt-4">No notifications to display</h3>
  }

  async function acceptInvite(event, doc_id) {
    event.preventDefault()

    await firebase.db.collection('friend_requests').doc(doc_id).update({
      accepted_at: new Date()
    })
    window.location.reload()
  }

  async function declineInvite(event, doc_id) {
    event.preventDefault()

    await firebase.db.collection('friend_requests').doc(doc_id).update({
      declined_at: new Date()
    })
    window.location.reload()
  }

  async function acceptTripInvite(event, doc_id) {
    event.preventDefault()

    await firebase.db.collection('trip_invites').doc(doc_id).update({
      accepted_at: new Date()
    })
    window.location.reload()
  }

  async function declineTripInvite(event, doc_id) {
    event.preventDefault()

    await firebase.db.collection('trip_invites').doc(doc_id).update({
      declined_at: new Date()
    })
    window.location.reload()
  }

  return (
    <div className="row">
      <div className="col-12">

        <div className="row">
          <div className="col-12">
            {invites && invites.map((invite, index) => {
              return (
                <UsersCardStyle key={index} className="card my-3 shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7 d-flex align-items-center">
                        <h5 className="mb-0">Sender: {invite.sender_email}</h5>
                      </div>
                      <div className="col-5 d-flex justify-content-end align-items-center">
                        <button onClick={e => declineInvite(e, invite.id)} className="btn btn-danger mr-2">
                          Decline
                        </button>
                        <button onClick={e => acceptInvite(e, invite.id)} className="btn btn-success">
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                </UsersCardStyle>
              )
            })}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {tripInvites && tripInvites.map((invite, index) => {
              return (
                <UsersCardStyle key={index} className="card my-3 shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7 d-flex align-items-center">
                        <h5 className="mb-0">
                          <strong>{invite.trip_title}</strong> <small className="text-muted">(from: {invite.sender_email})</small>
                        </h5>
                      </div>
                      <div className="col-5 d-flex justify-content-end align-items-center">
                        <button onClick={e => declineTripInvite(e, invite.id)} className="btn btn-danger mr-2 mb-2">
                          Decline
                        </button>
                        <button onClick={e => acceptTripInvite(e, invite.id)} className="btn btn-success mb-2">
                          Accept
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-8">
                        <p>Start At: {moment(invite.trip_start_at).format('YYYY-MM-DD hh:mm')}</p>
                        <p>End At: {moment(invite.trip_end_at).format('YYYY-MM-DD hh:mm')}</p>
                        <p>{invite.trip_description}</p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <EventMap event={{coord_lat: invite.trip_coord_lat, coord_lng: invite.trip_coord_lng}} index={index} />
                      </div>
                    </div>
                  </div>
                </UsersCardStyle>
              )
            })}
          </div>
        </div>

      </div>
    </div>
  )
}

/**
 * Export component
 */

export default NotificationList
