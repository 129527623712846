/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function BackToHomeNavbar() {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-md navbar-light bg-light px-0">
        <Link id="a-mktg-navbar-brand" className="navbar-brand text-dark" to="/home">
          <h3 className="mb-0"><strong>Nomadic</strong> Planner</h3>
        </Link>

        <button className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar-welcome"
                aria-label="Navbar Menu">
          <span className="navbar-toggler-icon"></span>
        </button>

        <a className="btn btn-link sr-only sr-only-focusable"
           href="#start-of-content"
           tabIndex="1">
          Skip to main content.
        </a>

        <div className="collapse navbar-collapse" id="navbar-welcome">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item text-center"></li>
          </ul>

          <ul className="navbar-nav">
            <li className="nav-item text-center">
              <Link id="a-users-navbar-home" to="/home"
                    className="nav-link alpha-6">
                <b>Home</b>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

/**
 * Export component
 */

export default BackToHomeNavbar
