/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function AcceptableUse() {
  return (
    <>
      <section id="acceptable-use" className="my-4">
        <h3 className="my-3">
          Acceptable Use
        </h3>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            Here is a list of unacceptable ways of using the Service. Most of these
            are common sense like don't lie, break laws, abuse people, steal data, and so on.
            Please behave yourself.
          </div>
        </div>

        <p id="acceptable-use-p1">
          We expect all Users to behave responsibly while using the Service. For example:
        </p>

        <ul>
          <li id="acceptable-use-li1">
            Don't use the Service for any purpose in violation of local, state,
            national, or international law. We are based in the USA and are not aware
            of all international laws and how the site might be used by travelers,
            please keep local laws in mind as you are using and always keep your data safe.
          </li>
          <li id="acceptable-use-li2">
            Don't upload, post, or transmit Content that infringes the copyright,
            trademark or other intellectual property or proprietary rights of another
            party.
          </li>
          <li id="acceptable-use-li3">
            Don't upload, post, or transmit Content that contains unsolicited
            communications, advertisements, or spam.
          </li>
          <li id="acceptable-use-li4">
            Don't upload, post, or transmit Content that contains viruses, bots,
            worms, or similar harmful malware.
          </li>
          <li id="acceptable-use-li5">
            Don't attempt to de-anonymize other Users by seeking their emails,
            passwords, or other private information.
          </li>
          <li id="acceptable-use-li6">
            Don't bully, intimidate, or harass any User.
          </li>
          <li id="acceptable-use-li7">
            Don't use a robot, spider, manual or other automatic processes or
            devices to data-mine, data-crawl, scrape, or index the Service in any
            manner.
          </li>
          <li id="acceptable-use-li8">
            Don't hack or interfere with the Service, its servers, or any connected
            networks.
          </li>
          <li id="acceptable-use-li9">
            Don't adapt, alter, license, sublicense, or translate the Service for
            your own personal or commercial use.
          </li>
          <li id="acceptable-use-li10">
            Don't register under a different email after your account has
            been suspended or terminated.
          </li>
          <li id="acceptable-use-li11">
            Don't bypass any measures we've put in place to secure the Services.
          </li>
          <li id="acceptable-use-li12">
            Don't take any action that imposes an unreasonable load on our
            infrastructure, or on our third-party providers. We reserve the right
            to determine what is a reasonable load.
          </li>
        </ul>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default AcceptableUse
