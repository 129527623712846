/**
 * Dependencies
 */

import React from 'react'
import { ProfileStyle } from './styles/index'

/**
 * Define view
 */

function Profile() {
  return (
    <ProfileStyle>
      <div className="row mx-auto">
        <div className="col-12">
          Profile
        </div>
      </div>
    </ProfileStyle>
  )
}

/**
 * Export view
 */

export default Profile
