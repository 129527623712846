/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function HistoricalAccounts() {
  return (
    <>
      <section id="historical-accounts" className="my-5">
        <h4 className="my-4">
          Brand Accounts
        </h4>

        <p id="accounts-p1">
          If you would like to create a historical record
          or an account for an icon/brand, please read this blog post
          ( <a href="www.NomadicPlanner.com/historical">www.NomadicPlanner.com/historical</a> )
          so we can work with you to make it perfect.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default HistoricalAccounts
