/**
 * Dependencies
 */

import React, { useState } from 'react'

/**
 * Define component
 */

function LinksField(props) {
  const [value, setValue] = useState('')

  function handleOnChange(event) {
    event.preventDefault()
    setValue(event.target.value)
  }

  return (
    <div className="form-group my-2">
      <label htmlFor={`input-url-${props.index}`}>Link #{props.index}</label>
      <input id={`input-url-${props.index}`} name={`url_${props.index}`}
             type="url" className="form-control input-url" value={value}
             onChange={handleOnChange}></input>
    </div>
  )
}

/**
 * Export component
 */

export default LinksField
