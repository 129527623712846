/**
 * Dependencies
 */

import React from 'react'
import { PollsShowStyle } from './styles/index'

/**
 * Define view
 */

function PollsShow() {
  return (
    <PollsShowStyle>
      <div className="row mx-auto">
        <div className="col-12">
          PollsShow
        </div>
      </div>
    </PollsShowStyle>
  )
}

/**
 * Export view
 */

export default PollsShow
