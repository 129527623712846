/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { TripLinkCardStyle } from './styles/index'

/**
 * Define component
 */

function TripList(props) {
  if (!props.trips || props.trips.length === 0) {
    return <h3 className="text-center">No trips to display</h3>
  }

  return (
    <div className="row">
      <div className="col-12">

        <div className="row">
          <div className="col-12">
            {props.trips && props.trips.map((trip, index) => {
              return (
                <Link key={index} to={`/trips/${trip.id}`} style={{"color": "inherit", "textDecoration": "none"}}>
                  <TripLinkCardStyle className="card my-3 shadow-sm">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-10">
                          <h5 className="mb-0">{trip.title}</h5>
                          {trip.description ? trip.description.substring(0,42) : null}<br/>
                          <span className="mr-2">Start: <span className="text-muted">{moment(trip.start_at).format('YYYY-MM-DD hh:mm')}</span></span>
                          <span>End: <span className="text-muted">{moment(trip.end_at).format('YYYY-MM-DD hh:mm')}</span></span><br/>
                          {trip.is_invited ? <span className="badge badge-warning">Invited</span> : null}
                        </div>
                        <div className="col-2 d-flex justify-content-end align-items-center">
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                  </TripLinkCardStyle>
                </Link>
              )
            })}
          </div>
        </div>

      </div>
    </div>
  )
}

/**
 * Export component
 */

export default TripList
