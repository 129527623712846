/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function WhatWeCollect() {
  return (
    <>
      <section id="info-we-collect" className="my-5">
        <h4 className="my-4">
          Information We Collect
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We only collect the bare minimum of personal information.
          </div>
        </div>

        <p id="info-we-collect-p1">
          We do not intentionally collect <b>sensitive personal information</b>,
          such as social security numbers, genetic data, health information, or
          religious information.
        </p>

        <p id="info-we-collect-p2">
          In order for you to create an account on Nomadic Planner and use our Services, we
          need to collect and process some information. Depending on your use of the
          Services, that may include:
        </p>

        <ul>
          <li id="info-we-collect-li1">
            Your email provided during account registration.
          </li>
          <li id="info-we-collect-li2">
            Your locale, which you can change within the Settings section of the Service.
          </li>
          <li id="info-we-collect-li3">
            Reference IDs of payment information stored with our payment processor
            Stripe. We do NOT store sensitive payment or financial information for
            any credit cards or transactions used within the Service.
          </li>
          <li id="info-we-collect-li4">
            Information about your activity on and interaction with Nomadic Planner
            (such as your Internet Protocol address, the type of device or browser
            you use, and your actions on the site).
          </li>
          <li id="info-we-collect-li5">
            Communications you send to us (for example, but not limited to, asking
            for support, providing feedback, or reporting a problem).
          </li>
        </ul>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default WhatWeCollect
