/**
 * Dependencies
 */

import React from 'react'
import { Redirect } from 'react-router-dom'
import firebase from '../../helpers/firebase'

/**
 * Define view
 */

function SignOut() {
  localStorage.removeItem('user')
  firebase.logout()
  return <Redirect to="/users/login" />
}

/**
 * Export view
 */

export default SignOut
