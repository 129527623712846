/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function OtherWebsites() {
  return (
    <>
      <section id="other-websites" className="my-5">
        <h4 className="my-3">
          Third-Party Services
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            If you follow a link to another website, what happens there is between
            you and them - not us.
          </div>
        </div>

        <p id="other-websites-p1">
          We use third-party services to help us provide the Service, but such use
          does not indicate that we endorse them or are responsible or liable for
          their actions. In addition, the Service may link (hyperlink) to third-party
          websites to facilitate its provision of Services to you. Nomadic Planner does not
          control any such sites and is not responsible for their availability,
          accuracy, content, advertising, products, or services. If you use these
          links, you will leave the Service. You agree that we are not responsible or
          liable for any loss or damage of any sort incurred as a result of any such
          dealings you may have on or through a third-party website. You should consult
          the terms and privacy policies of those other websites to understand
          your rights.
        </p>

        <p id="other-websites-p2">
          Nomadic Planner partners with Stripe for payment processing. When you upgrade your account or link a Stripe Account, you're
          agreeing to the payment processor's terms of service.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default OtherWebsites
