/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Redirect } from 'react-router-dom'
import leaflet from 'leaflet'
import moment from 'moment'
import uuid from 'uuid'
import { EventsFormStyle } from './styles/index'
import { UserContext } from '../contexts/index'
import { HomeNavbar, BackToLink } from '../components/Home/index'
import { LinksField } from '../components/Grouping/index'
import { MapSearchModal } from '../components/Trips/index'
import firebase from '../helpers/firebase'

/**
 * Constants
 */

const CenterIcon = new leaflet.Icon({
  iconUrl: 'https://storage.googleapis.com/nomadicplanner-image-assets/marker-icon-2x.png',
  shadowUrl: 'https://storage.googleapis.com/nomadicplanner-image-assets/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
})

/**
 * Define view
 */

function EventsForm(props) {
  const today = new Date()
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [trip, setTrip] = useState({})
  const [map, setMap] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [userLocation, setUserLocation] = useState([])
  const [coordLat, setCoordLat] = useState('')
  const [coordLng, setCoordLng] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imagePath, setImagePath] = useState('')
  const [startAt, setStartAt] = useState(moment(today).format('YYYY-MM-DDThh:mm'))
  const [endAt, setEndAt] = useState(moment(today).format('YYYY-MM-DDThh:mm'))
  const [links, setLinks] = useState([])
  const [linkFields, setLinkFields] = useState([<LinksField key={1} index={1} links={links} setLinks={setLinks} />])

  useEffect(() => {
    async function fetchTrip() {
      if (!props.match.params.id) {
        return setTrip(false)
      }

      const tripRef = await firebase.db.collection('trips').doc(props.match.params.id)
      const tripSnapshot = await tripRef.get()

      if (tripSnapshot.exists) {
        const trip_data = Object.assign({}, tripSnapshot.data(), {id: tripSnapshot.id})
        setTrip(trip_data)

        setStartAt(moment(trip_data.start_at).format('YYYY-MM-DDThh:mm'))
        setEndAt(moment(trip_data.start_at).format('YYYY-MM-DDThh:mm'))

        if (userContext) setIsLoading(false)
        setupMap(trip_data)
      } else {
        return setTrip(false)
      }
    }
    fetchTrip()

    function setupMap(trip_data) {
      let init_map = leaflet.map('map').setView([trip_data.coord_lat, trip_data.coord_lng], 11)

      setCoordLat(trip_data.coord_lat)
      setCoordLng(trip_data.coord_lng)

      leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(init_map)

      const center_marker = leaflet.marker([trip_data.coord_lat, trip_data.coord_lng], { icon: CenterIcon })
      center_marker.addTo(init_map)

      let event_form = document.getElementById('new-events-form')

      leaflet.Control.Searchbar = leaflet.Control.extend({
        options: {
          position: 'topleft'
        },
        onAdd: () => {
          let div = leaflet.DomUtil.create('div')
          leaflet.DomUtil.setClass(div, 'leaflet-bar leaflet-control')

          let a = leaflet.DomUtil.create('a')
          a.innerHTML = '&#x1F50D;'
          div.appendChild(a)

          a.setAttribute('href', '#')
          a.setAttribute('title', 'Search map')
          a.setAttribute('aria-label', 'Search map')
          a.setAttribute('role', 'button')

          a.addEventListener('click', (e) => {
            e.preventDefault()
            setOpenModal(true)
          })

          return div
        }
      })
      init_map.addControl(new leaflet.Control.Searchbar())

      leaflet.Control.MyLocation = leaflet.Control.extend({
        options: {
          position: 'topleft'
        },
        onAdd: () => {
          let div = leaflet.DomUtil.create('div')
          leaflet.DomUtil.setClass(div, 'leaflet-bar leaflet-control')

          let a = leaflet.DomUtil.create('a')
          a.innerHTML = '&#x25c9;'
          div.appendChild(a)

          a.setAttribute('href', '#')
          a.setAttribute('title', 'Locate me')
          a.setAttribute('aria-label', 'Locate me')
          a.setAttribute('role', 'button')

          a.addEventListener('click', (e) => {
            e.preventDefault();
            init_map.locate({ setView: true, maxZoom: 11 })
          })

          return div
        }
      })
      init_map.addControl(new leaflet.Control.MyLocation())

      init_map.on('locationfound', (event) => {
        center_marker.setLatLng(event.latlng)

        event_form.elements.coord_lat.value = event.latlng.lat
        event_form.elements.coord_lng.value = event.latlng.lng

        setCoordLat(event.latlng.lat)
        setCoordLng(event.latlng.lng)
      })

      init_map.on('moveend', () => {
        let coords = init_map.getCenter()
        center_marker.setLatLng(coords)

        event_form.elements.coord_lat.value = coords.lat
        event_form.elements.coord_lng.value = coords.lng

        setCoordLat(coords.lat)
        setCoordLng(coords.lng)
      })

      setMap(init_map)
    }
  }, [])

  function addLinkField(event) {
    event.preventDefault()

    if (linkFields.length < 10) {
      const next_index = linkFields.length + 1
      setLinkFields(linkFields.concat(<LinksField key={next_index} index={next_index} links={links} setLinks={setLinks} />))
    }
  }

  async function handleOnSubmit(event) {
    event.preventDefault()

    try {
      const now = new Date()

      let link_fields = document.getElementsByClassName('input-url')
      let all_links = []
      for (let i = 0; i < link_fields.length; i++) {
        all_links.push(link_fields[i].value)
      }

      await firebase.db.collection('events').doc(uuid.v4()).set({
        trip_id: trip.id,
        planner_email: userContext.email,
        planner_uid: userContext.uid,
        title: title,
        description: description,
        image_path: imagePath,
        start_at: startAt,
        end_at: endAt,
        coord_lat: coordLat,
        coord_lng: coordLng,
        links: all_links,
        modified_at: now,
        created_at: now,
      })
    } catch(err) {
      console.error(err)
    }

    props.history.push(`/trips/${trip.id}`)
  }

  if (!trip) {
    return <Redirect to="/home" />
  }

  return (
    <EventsFormStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      <main className="bg-white shadow py-4">
        <div className="container">
          <BackToLink url={`/trips/${props.match.params.id}`} text="Trip" />
        </div>

        {isLoading ? null :
          <section id="section-events-new-form">
            <div className="row mt-3 justify-content-center">
              <div className="col-12 col-lg-6">

                <div className="card mb-5 anim-fade-up shadow-lg">
                  <div className="card-block p-3">
                    <h3>
                      New Event Form<br/>
                      <small className="text-muted">{trip ? trip.title : null}</small>
                    </h3>

                    <div className="row">
                      <div className="col-12">

                        <form id="new-events-form" onSubmit={handleOnSubmit}>
                          <div id="map" className="bg-dark" style={{"height": "400px", "width": "100%"}}></div>

                          <input type="hidden" name="coord_lat" value={coordLat}></input>
                          <input type="hidden" name="coord_lng" value={coordLng}></input>

                          <div className="form-group my-2">
                            <label htmlFor="input-title">Title</label>
                            <input id="input-title" type="text" name="title"
                                   value={title}
                                   onChange={e => setTitle(e.target.value)}
                                   className="form-control" required></input>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="form-group my-2">
                                <label htmlFor="input-start_at">Start At</label>
                                <input id="input-start_at" type="datetime-local"
                                       name="start_at" className="form-control"
                                       value={startAt}
                                       onChange={e => setStartAt(e.target.value)}
                                       required></input>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group my-2">
                                <label htmlFor="input-end_at">End At</label>
                                <input id="input-end_at" type="datetime-local"
                                       name="end_at" className="form-control"
                                       value={endAt}
                                       onChange={e => setEndAt(e.target.value)}
                                       required></input>
                              </div>
                            </div>
                          </div>

                          <div className="form-group my-2">
                            <label htmlFor="textarea-description">Description</label>
                            <textarea id="textarea-description" name="description" rows="3"
                                      onChange={e => setDescription(e.target.value)}
                                      className="form-control"
                                      defaultValue={description}></textarea>
                          </div>

                          <button onClick={addLinkField} className="btn btn-sm btn-primary">
                            <i className="fa fa-plus"></i>
                          </button>

                          {linkFields.map(field => field)}

                          <button type="submit" className="btn btn-block btn-success py-2 my-4">
                            Save Event
                          </button>
                        </form>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        }

        <MapSearchModal openModal={openModal}
                        setOpenModal={setOpenModal}
                        setUserLocation={setUserLocation}
                        setCoordLat={setCoordLat}
                        map={map} />
      </main>
    </EventsFormStyle>
  )
}

/**
 * Export view
 */

export default EventsForm
