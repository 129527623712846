/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Accounts() {
  return (
    <>
      <section id="accounts" className="my-5">
        <h4 className="my-4">
          Your Account
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            A human must create your account; you must be 18 years or older; you
            must provide a valid email address; and you may not have more than one
            account. You alone are responsible for your account and anything that
            happens while you are signed in to or using your account. You are
            responsible for keeping your account secure (safe password and content
            you choose to upload).
          </div>
        </div>

        <p id="accounts-p1">
          To access and use the Nomadic Planner Service you'll need to register for an account
          using a valid email address. We have a few simple rules for accounts.
        </p>

        <ul>
          <li id="accounts-li1">
            You must be a human to create an account. Accounts registered by automated
            methods are not permitted.
          </li>
          <li id="accounts-li2">
            You must be 18 years of age or older.
          </li>
          <li id="accounts-li3">
            To access and use the Services, you must register for an account by
            providing a valid email address.
          </li>
          <li id="accounts-li4">
            You acknowledge that Nomadic Planner will use the email address you provide as the
            primary method for communication with you.
          </li>
          <li id="accounts-li5">
            You will not provide any false personal information on Nomadic Planner, or create
            an account for anyone other than yourself.
          </li>
          <li id="accounts-li6">
            One person may create and maintain no more than one account, for more information see
            ( <a href="www.NomadicPlanner.com/historical">www.NomadicPlanner.com/historical</a> ).
          </li>
          <li id="accounts-li7">
            You are responsible for maintaining the security of your account and
            password. Nomadic Planner cannot and will not be liable for any loss or damage
            from your failure to comply with this security obligation.
          </li>
          <li id="accounts-li8">
            You must immediately notify Nomadic Planner of any unauthorized uses of your
            account or any other breaches of security.
          </li>
          <li id="accounts-li9">
            You are responsible for all activity and content such as images, audio,
            and written content uploaded, collected, generated, stored, displayed,
            distributed, transmitted or exhibited on or in connection with your
            account.
          </li>
          <li id="accounts-li10">
            A breach or violation of any term in the Terms of Service, as determined
            at the sole discretion of Nomadic Planner, will result in an immediate termination
            of your Services.
          </li>
          <li id="accounts-li11">
            If we disable your account, you will not create another one without our
            permission.
          </li>
          <li id="accounts-li12">
            These Terms may not be transferred or assigned by you. Any attempted
            transfer or assignment by you will be null and void.
          </li>
        </ul>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Accounts
