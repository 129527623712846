/**
 * Dependencies
 */

import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

/**
 * Define component
 */

function InviteEmailDialog(props) {
  return (
    <Dialog open={props.open}
            onClose={props.onClose}
            fullWidth={true}
            aria-labelledby="dialog-email-invite">
      <DialogTitle id="form-dialog-title">Trip Invite</DialogTitle>
      <DialogContent>
        <TextField autoFocus
            id="input-email"
            label="Email Address"
            type="email"
            value={props.emailToInvite}
            onChange={(e) => props.setEmailToInvite(e.target.value)}
            fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.sendEmailInvite} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Export component
 */

export default InviteEmailDialog
