/**
 * Dependencies
 */

import React, { useEffect } from 'react'
import leaflet from 'leaflet'

/**
 * Define component
 */

function EventMap(props) {
  useEffect(() => {
    function initMap() {
      let init_map = leaflet.map(`event-map-${props.index}`, {
        attributionControl: false,
        zoomControl: false,
      }).setView([props.event.coord_lat, props.event.coord_lng], 11)

      leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(init_map)
    }

    if (!props.isLoading) initMap()
  }, [props.isLoading])

  return <div id={`event-map-${props.index}`} className="bg-dark rounded" style={{"height": "200px", "width": "100%"}}></div>
}

/**
 * Export component
 */

export default EventMap
