/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { UsersCardStyle, UserListStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function FriendList(props) {
  const userContext = useContext(UserContext)
  const [myFriends, setMyFriends] = useState(props.friends)

  async function unfriend(event, user_uid, user_email) {
    event.preventDefault()

    const yes = window.confirm('Are you sure? This permanently deletes this Friend Request.')

    if (yes) {
      const friendRequestRef = await firebase.db.collection('friend_requests')
        .where('receiver_uid', '==', user_uid)
        .where('sender_uid', '==', userContext.uid)
      const friendRequestsSnapshot = await friendRequestRef.get()

      friendRequestsSnapshot.docs.forEach(async doc => {
        await firebase.db.collection('friend_requests').doc(doc.id).delete()
      })

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  return (
    <UserListStyle>
      <div className="row">
        <div className="col-12">
          <h3>Friends</h3>

          {myFriends && myFriends.map((user, index) => {
            return (
              <UsersCardStyle key={index} className="card my-3 shadow-sm">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      {user.username ? <small className="text-muted">{user.username}</small> : null}
                      <h5 className="mb-0"><b>{user.email}</b></h5>
                    </div>
                    <div className="col-5 d-flex justify-content-end align-items-center">
                      <button onClick={e => unfriend(e, user.uid, user.email)} className="btn btn-sm btn-danger">
                        <i className="fa fa-close"></i> Unfriend
                      </button>
                    </div>

                    {user.hometown ?
                      <div className="col-12 col-md-6 col-lg-4">
                        <small>Hometown: <span className="text-muted">{user.hometown}</span></small>
                      </div> : null}

                    {user.website ?
                      <div className="col-12 col-md-6 col-lg-4">
                        <small>Website: <span className="text-muted"><a href={user.website} target="_blank">{user.website}</a></span></small>
                      </div> : null}

                    {user.facebook ?
                      <div className="col-12 col-md-6 col-lg-4">
                        <small>Facebook: <span className="text-muted"><a href={user.facebook} target="_blank">{user.facebook}</a></span></small>
                      </div> : null}

                    {user.twitter ?
                      <div className="col-12 col-md-6 col-lg-4">
                        <small>Twitter: <span className="text-muted"><a href={user.twitter} target="_blank">{user.twitter}</a></span></small>
                      </div> : null}

                    {user.instagram ?
                      <div className="col-12 col-md-6 col-lg-4">
                        <small>Instagram: <span className="text-muted"><a href={user.instagram} target="_blank">{user.instagram}</a></span></small>
                      </div> : null}

                    {user.bio ?
                      <div className="col-12">
                        <small>Bio: <span className="text-muted">{user.bio.substring(0,300)}</span></small>
                      </div> : null}
                  </div>
                </div>
              </UsersCardStyle>
            )
          })}
        </div>
      </div>
    </UserListStyle>
  )
}

/**
 * Export component
 */

export default FriendList
