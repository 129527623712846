/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Wrapup() {
  return (
    <>
      <section id="wrapup" className="my-5">
        <h4 className="my-4">
          Wrap-up
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            These are our official policies regarding User's data privacy and any
            conflicting information should be clarified by reading these policies.
          </div>
        </div>

        <p id="wrapup-p1">
          We may modify this Privacy Policy at any time, but we will give you 30 days
          of notice before changes take effect, either by notifying you on the Site or
          by sending you an email. By using the Service after that effective date, you
          agree to the new Terms. If you don't agree to them, you should delete your
          account before they take effect, otherwise your use of the Service will be
          subject to the new Terms.
        </p>

        <p id="wrapup-p2">
          This Privacy Policy is licensed under the
          <a href="https://creativecommons.org/licenses/by/4.0/">
            Creative Commons Attribution license
          </a>. You may use it freely under the terms of the Creative Commons license.
        </p>

        <p id="wrapup-p3">
          If you have questions regarding Nomadic Planner's Privacy Policy or information
          practices, please ask for support at
          <a href="mailto:support@nomadicplanner.com">support@nomadicplanner.com</a>
          before using the Services.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Wrapup
