/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Introduction() {
  return (
    <>
      <div className="card text-white bg-primary my-3">
        <div className="card-block p-3">
          Summaries are provided for convenience only and are not legally binding.
          We've done our best to offer clear and simple explanations of what
          everything means.
        </div>
      </div>

      <p id="privacy-policy-p1">
        This Privacy Policy was written in English (US). To the extent any translated
        version of this agreement conflicts with the English version, the English
        version will control.
      </p>

      <p id="privacy-policy-p2">
        This Privacy Policy describes the information we collect from you and how
        we use that information. It applies to any of the Services that Nomadic Planner provides.
      </p>

      <p id="privacy-policy-p3">
        We only collect the minimum amount of personal information that is necessary
        to fulfill the purpose of your interaction with us; we don't sell it to
        third parties; and we only use it as this Privacy Policy describes.
      </p>

      <p id="privacy-policy-p4">
        Protecting your information, and being transparent about the ways we use it,
        is one of the core commitments we make to you. You entrust us with your
        information, and we take our responsibility to protect that trust to heart.
      </p>

      <p id="privacy-policy-p5">
        Please read this Agreement carefully before accessing or using our Services.
        <b>You may not access or use any Services unless you agree to all provisions
        within this agreement related to our collection and usage of information.
        </b> If you do not understand all of the provisions of this Agreement, please
        ask for support at
        <a href="mailto:support@nomadicplanner.com">support@nomadicplanner.com</a>
        before using the Services.
      </p>
    </>
  )
}

/**
 * Export component
 */

export default Introduction
