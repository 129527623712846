/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function InfoRetention() {
  return (
    <>
      <section id="info-retention" className="my-5">
        <h4 className="my-4">
          Information Retention Policy
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            Nomadic Planner will retain a Users' personal information for as long as your account
            is active or as needed to provide Services.
          </div>
        </div>

        <p id="info-retention-p1">
          If your account is closed for any reason, you will no longer have access
          to Content associated with your account. To preserve the integrity of our
          databases, we may retain information submitted by Users for an indefinite
          length of time as necessary to comply with our legal obligations, resolve
          disputes and enforce our agreements. If required by law, we will delete
          personal information by erasing it from our database. However, you understand
          that any termination of your account may involve deletion of any Content
          stored in association with your account for which Nomadic Planner will have no
          liability whatsoever.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default InfoRetention
