/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function HomeDashboardLink() {
  return (
    <div className="row">
      <div className="col-12 bg-warning py-2">
        <div className="container">
          <Link to="/home" className="btn btn-md btn-light">
            Home
          </Link>
        </div>
      </div>
    </div>
  )
}

/**
 * Export component
 */

export default HomeDashboardLink
