/**
 * Dependencies
 */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from './helpers/index'
import { PollsForm, PollsShow } from '../views/polls/index'
import { ErrorBoundary } from '../views/index'
import uuid from 'uuid'

/**
 * Define router
 */

const EventsRouter = [
  <Route key={uuid.v4()} exact path='/events' render={() => <Redirect to='/home' />} />,
  <PrivateRoute key={uuid.v4()} path='/events/:id/polls/new' component={PollsForm} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/events/:trip_id/polls/:id' component={PollsShow} errorBoundary={ErrorBoundary} />,
]

/**
 * Export router
 */

export default EventsRouter
