/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function IntellectualProperty() {
  return (
    <>
      <section id="intellectual-property" className="my-4">
        <h4 className="my-3">
          Our Intellectual Property
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We own the Service and all of our Content. In order to use our content,
            we give you certain rights to it.
          </div>
        </div>

        <p id="intellectual-property-p1">
          The Site and Service (excluding Content provided by Users) constitutes
          Nomadic Planner's intellectual property. Nomadic Planner retains ownership
          of all intellectual property rights of any kind related to the Site and
          Service. We reserve all rights that are not expressly granted to you under
          this Agreement or by law. The look and feel of the Site and Service is
          copyrighted by Free Coffee, Inc. All rights reserved. You may not duplicate,
          copy, or reuse any portion of the HTML/CSS, Javascript, or visual design
          elements or concepts without express written permission from Nomadic Planner.
        </p>

        <p id="intellectual-property-p2">
          We appreciate hearing from our Users and welcome your comments regarding the
          Service. With any comments, suggestions or recommendations regarding the Service
          ("Feedback") you hereby assign to us all rights, title and interest in and
          to the Feedback. Accordingly, your Feedback will not be subject to any obligation of
          confidentiality and we will not be liable to you for any use or disclosure
          of any Feedback. Also, we will be entitled to unrestricted use of the Feedback
          for any purpose whatsoever, commercial or otherwise, without compensation to
          you or any other person.
        </p>

        <p id="intellectual-property-p3">
          This Agreement is licensed under the <a href="https://creativecommons.org/licenses/by/4.0/">
          Creative Commons Attribution license</a> You may use it freely under the
          terms of the Creative Commons license.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default IntellectualProperty
