/**
 * Dependencies
 */

import React from 'react'
import { PrivacyStyle } from './styles/index'
import { Navbar, Footer } from '../../components/Landing/index'
import {
  Introduction,
  WhatWeCollect,
  UsageOfInfo,
  InfoSharing,
  InfoControl,
  InfoRetention,
  InfoProtection,
  Cookies,
  DoNotTrack,
  DataTransfers,
  NoChildren,
  OtherWebsites,
  Wrapup,
} from '../../components/Privacy/index'

/**
 * Define view
 */

function Privacy() {
  return (
    <PrivacyStyle>
      <header>
        <div className="container border-right border-bottom border-left">
          <Navbar />
        </div>

        <div id="start-of-content"></div>
      </header>

      <main>
        <div className="container">

          <div className="row">
            <div className="col-12">
              <h1 id="h1-mktg-privacy" className="mt-5">Privacy Policy</h1>

              <p id="terms-last-updated" className="font-weight-bold">
                Last Updated: May 3, 2019
                <br/>
                Effective Date: May 3, 2019
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">

              <article id="privacy-policy">
                <Introduction />
                <WhatWeCollect />
                <UsageOfInfo />
                <InfoSharing />
                <InfoControl />
                <InfoRetention />
                <InfoProtection />
                <Cookies />
                <DoNotTrack />
                <DataTransfers />
                <NoChildren />
                <OtherWebsites />
                <Wrapup />
              </article>

            </div>
          </div>

        </div>
      </main>

      <Footer />
    </PrivacyStyle>
  )
}

/**
 * Export view
 */

export default Privacy
