/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import moment from 'moment'
import { HomeStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import {
  HomeNavbar,
  AdminDashboardLink,
  HomeControls,
  TripList,
} from '../../components/Home/index'

/**
 * Define view
 */

function Home(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [trips, setTrips] = useState([])

  useEffect(() => {
    async function fetchTrips() {
      const now = moment(new Date()).format('YYYY-MM-DDThh:mm')

      const tripsRef = await firebase.db.collection('trips')
        .where('planner_uid', '==', userContext.uid)
        .where('end_at', '>=', now)
        .orderBy('end_at', 'asc')
        .orderBy('start_at', 'asc')
      const tripsSnapshot = await tripsRef.get()
      let trip_list = tripsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

      const tripInvitesRef = await firebase.db.collection('trip_invites')
        .where('receiver_email', '==', userContext.email)
        .where('declined_at', '==', null)
      const tripInvitesSnapshot = await tripInvitesRef.get()
      let trip_invites_list = tripInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
      trip_invites_list = trip_invites_list.filter(invite => invite.accepted_at != null)

      for (let i = 0; i < trip_invites_list.length; i++) {
        let tripRef = await firebase.db.collection('trips').doc(trip_invites_list[i].trip_id)
        let tripSnapshot = await tripRef.get()
        let trip = Object.assign({}, tripSnapshot.data(), {id: tripSnapshot.id, is_invited: true})

        if (trip.end_at >= now) {
          trip_list.push(trip)
        }
      }

      trip_list = trip_list.sort((a,b) => {
        return new Date(a.end_at) - new Date(b.end_at)
      })

      setTrips(trip_list)
      setIsLoading(false)
    }

    if (userContext) fetchTrips()
  }, [])

  return (
    <HomeStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      {isLoading ? null :
        <main className="bg-white shadow pt-2 pb-5">
          <AdminDashboardLink isAdmin={userContext.isAdmin}/>

          <div className="container">
            <HomeControls />
            <TripList trips={trips} />
          </div>
        </main>
      }
    </HomeStyle>
  )
}

/**
 * Export view
 */

export default Home
