/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function HomeControls() {
  return (
    <div className="row mt-3">
      <div className="col-6">
        <Link to="/trips/new" className="btn btn-lg btn-primary">
          Add trip
        </Link>
      </div>

      <div className="col-6 d-flex justify-content-end align-items-end">
        <Link to="/trips/archive" className="btn btn-link">
          Archive
        </Link>
      </div>
    </div>
  )
}

/**
 * Export component
 */

export default HomeControls
