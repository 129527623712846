/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { UsersCardStyle, UserListStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function UserList(props) {
  const userContext = useContext(UserContext)
  const [invites, setInvites] = useState([])
  const [myUsers, setMyUsers] = useState(props.users)

  useEffect(() => {
    async function fetchInvites() {
      const invitesRef = firebase.db.collection('friend_requests')
        .where('sender_uid', '==', userContext.uid)
      const invitesSnapshot = await invitesRef.get()
      const objInvites = invitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

      setInvites(objInvites.map(invite => invite.receiver_email))
    }

    if (userContext) fetchInvites()
  }, [])

  async function sendInvite(event, user_uid, user_email) {
    event.preventDefault()

    await firebase.db.collection('friend_requests').add({
      accepted_at: null,
      declined_at: null,
      sent_at: new Date(),
      receiver_email: user_email,
      receiver_uid: user_uid,
      sender_email: userContext.email,
      sender_uid: userContext.uid,
    })
    window.location.reload()
  }

  return (
    <UserListStyle>
      <div className="row">
        <div className="col-12">

          { (!props.isFiltered) ? <h4 className="text-center mt-4"><b>Public Users ({myUsers.length ? myUsers.length : 0 })</b></h4> :
            <div className="row">
              <div className="col-12">
              {myUsers && myUsers.map((user, index) => {
                return (
                  <UsersCardStyle key={index} className="card my-3 shadow-sm">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-7">
                          {user.username ? <small className="text-muted">{user.username}</small> : null}
                          <h5 className="mb-0"><b>{user.email}</b></h5>
                        </div>
                        <div className="col-5 d-flex justify-content-end align-items-center">
                          {invites.includes(user.email) ?
                            <span>Invited</span> :
                            <button onClick={e => sendInvite(e, user.uid, user.email)} className="btn btn-success">
                              <i className="fa fa-envelope-o"></i> Friend
                            </button>
                          }
                        </div>

                        {user.hometown ?
                          <div className="col-12 col-md-6 col-lg-4">
                            <small>Hometown: <span className="text-muted">{user.hometown}</span></small>
                          </div> : null}

                        {user.website ?
                          <div className="col-12 col-md-6 col-lg-4">
                            <small>Website: <span className="text-muted"><a href={user.website} target="_blank">{user.website}</a></span></small>
                          </div> : null}

                        {user.facebook ?
                          <div className="col-12 col-md-6 col-lg-4">
                            <small>Facebook: <span className="text-muted"><a href={user.facebook} target="_blank">{user.facebook}</a></span></small>
                          </div> : null}

                        {user.twitter ?
                          <div className="col-12 col-md-6 col-lg-4">
                            <small>Twitter: <span className="text-muted"><a href={user.twitter} target="_blank">{user.twitter}</a></span></small>
                          </div> : null}

                        {user.instagram ?
                          <div className="col-12 col-md-6 col-lg-4">
                            <small>Instagram: <span className="text-muted"><a href={user.instagram} target="_blank">{user.instagram}</a></span></small>
                          </div> : null}

                        {user.bio ?
                          <div className="col-12">
                            <small>Bio: <span className="text-muted">{user.bio.substring(0,300)}</span></small>
                          </div> : null}
                      </div>
                    </div>
                  </UsersCardStyle>
                )
              })}
              </div>
            </div>
          }
        </div>
      </div>
    </UserListStyle>
  )
}

/**
 * Export component
 */

export default UserList
