/**
 * Dependencies
 */

import styled from 'styled-components'

/**
 * Define styles
 */

const TripsFormStyle = styled.div`
  .anim-fade-up {
    animation: fade_up 0.5s ease-in;
  }

  @keyframes fade_up {
    0% {
      opacity: 0.4;
      bottom: -40px;
    }

    100% {
      opacity: 1;
      bottom: 0px;
    }
  }
`

/**
 * Export styles
 */

export default TripsFormStyle
