/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { NavbarStyle } from './styles/index'

/**
 * Define component
 */

function Navbar() {
  return (
    <NavbarStyle>
      <nav className="navbar navbar-expand-lg px-0">
        <Link id="a-mktg-navbar-brand" className="navbar-brand text-dark" to="/">
          <h3 className="mb-0"><strong>Nomadic</strong> Planner</h3>
        </Link>

        <button className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar-welcome"
                aria-label="Navbar Menu">
          Menu
        </button>

        <div className="collapse navbar-collapse" id="navbar-welcome">
          <ul className="navbar-nav ml-auto"></ul>

          <ul className="navbar-nav">
            <li className="nav-item text-center mx-1 my-1">
              <Link id="a-mktg-navbar-sign-in" to="/users/login"
                 className="btn btn-block btn-lg btn-primary">
                Sign in
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </NavbarStyle>
  )
}

/**
 * Export component
 */

export default Navbar
