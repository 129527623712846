/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function InfoControl() {
  return (
    <>
      <section id="info-control" className="my-5">
        <h4 className="my-4">
          Access To Information
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We accept reasonable requests to edit stored information.
          </div>
        </div>

        <p id="info-control-p1">
          Your email is the sole personally identifiable information used to identify
          your account on the Service.
        </p>

        <p id="info-control-p2">
          Despite the above, you may wish to keep your personally identifiable
          information accurate, current, and complete. To make such a request you
          may contact us via email at
          <a href="mailto:privacy@nomadicplanner.com">privacy@nomadicplanner.com</a>.
          We will take reasonable steps to update or correct information in our
          possession that you have previously submitted via the Service.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default InfoControl
