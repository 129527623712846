/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import EventMap from './EventMap'
import Poll from './Poll'

/**
 * Define component
 */

function EventsList(props) {
  const userContext = useContext(UserContext)
  const events = props.events

  function deleteEvent(event, event_id) {
    event.preventDefault()

    const yes = window.confirm('Are you sure? This permanently deletes this Event.')

    if (yes) {
      firebase.db.collection('events').doc(event_id).delete()
      props.setEvents(events.filter(event => event.id !== event_id))
    }
  }

  function handleOpenPollDialog(event, event_id) {
    event.preventDefault()
    props.setPollEvent(event_id)
    props.setOpenPollDialog(true)
  }

  return (
    <>
      {events && events.map((event, index) => {
        return (
          <div key={event.id} className="card mt-3 mb-5 border border-secondary shadow-sm">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h5 className="mb-0">{event.title}</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="row mb-3">
                    <div className="col-12">
                      <p>
                        {event.description}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <p>
                        <strong>Starts At:</strong><br/> {(event && event.start_at) ? moment(event.start_at).format('YYYY-MM-DD hh:mm') : null}
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        <strong>Ends At:</strong><br/> {(event && event.end_at) ? moment(event.end_at).format('YYYY-MM-DD hh:mm') : null}
                      </p>
                    </div>

                    <div className="col-12">
                      {(event && event.links) ? event.links.map((link, index) => <a key={index} href={link} target="_blank">{link}</a>) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <EventMap event={event} index={index} />
                </div>
              </div>

              {event.polls.map(poll => <Poll key={poll.id} poll={poll}
                                             events={events} setEvents={props.setEvents}
                                             isInvitedTrip={props.isInvitedTrip}
                                             {...props} />)}
            </div>

            {props.isInvitedTrip ? null :
              <div className="card-footer">
                <button className="btn btn-sm btn-secondary rounded-pill" onClick={e => handleOpenPollDialog(e,event.id)}>
                  Create poll
                </button>

                <button onClick={e => deleteEvent(e, event.id)} className="btn btn-sm btn-link text-danger float-right"><i className="fa fa-trash"></i></button>
              </div>
            }
          </div>
        )
      })}
    </>
  )
}

/**
 * Export component
 */

export default EventsList
