/**
 * Dependencies
 */

import React from 'react'
import { PrivateRoute } from './helpers/index'
import {
  Admin,
  AdminEvents,
  AdminTrips,
  AdminUsers,
} from '../views/admin/index'
import { ErrorBoundary } from '../views/index'
import uuid from 'uuid'

/**
 * Define router
 */

const AdminRouter = [
  <PrivateRoute key={uuid.v4()} exact path='/admin' component={Admin} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/admin/events' component={AdminEvents} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/admin/trips' component={AdminTrips} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/admin/users' component={AdminUsers} errorBoundary={ErrorBoundary} />,
]

/**
 * Export router
 */

export default AdminRouter
