/**
 * Dependencies
 */

import React from 'react'
import { PollsFormStyle } from './styles/index'

/**
 * Define view
 */

function PollsForm() {
  return (
    <PollsFormStyle>
      <div className="row mx-auto">
        <div className="col-12">
          PollsForm
        </div>
      </div>
    </PollsFormStyle>
  )
}

/**
 * Export view
 */

export default PollsForm
