/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function LimitedLiability() {
  return (
    <>
      <section id="limited-liability" className="my-5">
        <h4 className="my-3">
          Limitation of Liability
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We will not be liable for damages or losses arising from your use or
            inability to use the Service or otherwise arising under this agreement.
          </div>
        </div>

        <p id="limited-liability-p2">
          Nomadic Planner won't be liable to you for any damages that arise from your using the
          Services. This includes if the Services are hacked or unavailable. This
          includes all types of damages (indirect, incidental, consequential, special
          or exemplary). And it includes all kinds of legal claims, such as breach of
          contract, breach of warranty, tort, or any other claim.
        </p>

        <p id="limited-liability-p3">
          If any provision of these terms is found invalid by a court of competent
          jurisdiction, you agree that the court should try to give effect to the
          parties' intentions as reflected in the provision and that other provisions
          of the Terms will remain in full effect.
        </p>

        <p id="limited-liability-p4">
          These Terms are governed by Wisconsin law, without reference to its conflict of
          laws provisions. You agree that any suit arising from the Services must take
          place in a court located in Madison, Wisconsin.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default LimitedLiability
