/**
 * Dependencies
 */

import React from 'react'
import { ConfirmEmailStyle } from './styles/index'

/**
 * Define view
 */

function ConfirmEmail() {
  return (
    <ConfirmEmailStyle>
      <div className="row mx-auto">
        <div className="col-12">
          ConfirmEmail
        </div>
      </div>
    </ConfirmEmailStyle>
  )
}

/**
 * Export view
 */

export default ConfirmEmail
