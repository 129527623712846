/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Indemnification() {
  return (
    <>
      <section id="indemnification" className="my-5">
        <h4 className="my-3">
          Indemnification
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            You are fully responsible for your use of the service.
          </div>
        </div>

        <p id="indemnification-p1">
          You agree to defend, indemnify and hold harmless Nomadic Planner and its licensee and
          licensors, and their employees, contractors, agents, officers and directors,
          from and against any and all claims, damages, obligations, losses, liabilities,
          costs or debt, and expenses (including but not limited to attorney's fees)
          arising from your use of and access to the Service or the Software, or from or
          in connection with any Content uploaded to the Service through your account
          by a third party using your account with your knowledge or consent.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Indemnification
