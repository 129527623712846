/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function DoNotTrack() {
  return (
    <>
      <section id="do-not-track" className="my-5">
        <h4 className="my-4">
          Do Not Track
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We do not respond to "Do Not Track" signals, because we don't let third
            party services track any of our Users.
          </div>
        </div>

        <p id="do-not-track-p1">
          "Do Not Track" is a privacy preference you can set in your browser if you
          do not want online services to collect and share certain kinds of
          information about your online activity from third party tracking services.
          We do not permit third-party services to track and collect your activity
          on our site. Because we do not share this kind of data with third party
          services or permit this kind of third party data collection on Nomadic Planner for
          any of our Users, we do not need to respond differently to an individual
          browser's Do Not Track setting.
        </p>

        <p id="do-not-track-p2">
          If you are interested in turning on your browser's privacy and Do Not Track
          settings, visit <a href="http://donottrack.us">donottrack.us</a> for
          browser-specific instructions.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default DoNotTrack
