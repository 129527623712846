/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import LinearProgress from '@material-ui/core/LinearProgress'
import { TripsArchiveStyle } from './styles/index'
import { HomeStyle } from '../users/styles/index'
import { UserContext } from '../../contexts/index'
import { TripList, BackToHomeNavbar, BackToLink } from '../../components/Home/index'
import firebase from '../../helpers/firebase'

/**
 * Define view
 */

function TripsArchive(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [trips, setTrips] = useState([])

  useEffect(() => {
    async function fetchTrips() {
      const tripsRef = await firebase.db.collection('trips')
        .where('planner_uid', '==', userContext.uid)
        .where('end_at', '<=', moment(new Date()).format('YYYY-MM-DDThh:mm'))
        .orderBy('end_at', 'desc')
        .orderBy('start_at', 'desc')
      const tripsSnapshot = await tripsRef.get()

      setTrips(tripsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id})))
      if (userContext) setIsLoading(false)
    }
    fetchTrips()
  }, [])

  return (
    <TripsArchiveStyle>
      <HomeStyle>
        <header className="bg-light">
          <BackToHomeNavbar />
        </header>

        {isLoading ? <LinearProgress /> : null}
      </HomeStyle>

      <main className="bg-white shadow py-4">
        <div className="container">
          <BackToLink url="/home" text="Home" />

          <TripList trips={trips} />
        </div>
      </main>
    </TripsArchiveStyle>
  )
}

/**
 * Export view
 */

export default TripsArchive
