/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Copyright() {
  return (
    <>
      <section id="copyright" className="my-4">
        <h4 className="my-3">
          Copyright
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We respect the intellectual property rights of others and we expect
            the same from our Users.
          </div>
        </div>

        <p id="copyright-p1">
          It is our policy to respond to claims of infringement in compliance with
          the Digital Millennium Copyright Act of 1998 ("DMCA"). Owners of intellectual
          property rights with good faith belief that Content uploaded into Nomadic Planner
          and made accessible via the Services infringes their copyright, trademark,
          or other intellectual property rights should notify our Copyright Agent by
          delivering a completed Notice of Infringement by emailing
          <a href="mailto:copyright@nomadicplanner.com">copyright@nomadicplanner.com</a> or
          written notice to the appropriate address set forth below.
        </p>

        <p id="copyright-p2">
          We reserve the right to delete or disable content alleged to be infringing,
          and to terminate accounts for repeat infringers. (We do this when appropriate
          and at our sole discretion.)
        </p>

        <p id="copyright-p3">
          For a notice to be effective it must contain the following information:
        </p>

        <ul>
          <li id="copyright-li1">
            <b>Identify the Web page URL(s)</b> within the Service containing the copyrighted
            work or intellectual property that you claim has been infringed. Describe
            the content on the page(s) you believe infringes upon the work identified,
            including whether the content is a particular image (and describe it in
            detail) or written work (including the text of the copyrighted work).
          </li>

          <li id="copyright-li2">
            <b>Provide your contact information</b>. Include your name, mailing
            address, telephone number, and email address.
          </li>

          <li id="copyright-li3">
            <b>Include the following statement: "I have a good faith belief that use of
            the copyrighted materials described above on the infringing web pages
            is not authorized by the copyright owner, or its agent, or the law.
            I have taken fair use into consideration."</b>
          </li>

          <li id="copyright-li4">
            <b>Also include the following statement: "I swear, under penalty of
              perjury, that the information in this notification is accurate and
              that I am the copyright owner, or am authorized to act on behalf of
              the owner, of an exclusive right that is allegedly infringed."</b>
          </li>

          <li id="copyright-li5">
            <b>Include an electronic or physical signature</b>.
          </li>
        </ul>

        <p id="copyright-p4">
          If you believe that your data that was removed (or to which access was
          disabled) is not infringing, or that you have the authorization from the
          copyright owner, the copyright owner's agent, or pursuant to the law, to
          upload and use the content, you may send a written counter-notice
          containing the following information to the Copyright Agent:
        </p>

        <ul>
          <li id="copyright-li6">
            <b>Identify the Web page URL(s)</b> within the Service where the content
            that was disabled appeared. The disabled content should have been
            identified by URL in the takedown notice. You simply need to copy the
            URL(s) that you want to challenge.
          </li>

          <li id="copyright-li7">
            <b>Provide your contact information</b>. Include your name, mailing
            address, telephone number, and email address.
          </li>

          <li id="copyright-li8">
            <b>Include the following statement: "I swear, under penalty of perjury,
            that I have a good-faith belief that the material was removed or
            disabled as a result of a mistake or misidentification of the material
            to be removed or disabled."</b> You may also choose to communicate the
            reasons why you believe there was a mistake or misidentification. If
            you think of your counter notice as a "note" to the complaining party,
            this is a chance to explain why they should not take the next step and
            file a lawsuit in response. This is yet another reason to work with a
            lawyer when submitting a counter notice.
          </li>

          <li id="copyright-li9">
            <b>Include the following statement: "I consent to the jurisdiction of
            Federal District Court for the judicial district in which my address is
            located (if in the United States, otherwise Wisconsin where Nomadic Planner is located),
            and I will accept service of process from the person who provided the
            DMCA notification or an agent of such person."</b>
          </li>

          <li id="copyright-li10">
            <b>Include your electronic or physical signature.</b>
          </li>
        </ul>

        <p id="copyright-p5">
          If a counter-notice is received by the Copyright Agent, the Company will
          send a copy of the counter-notice to the original complaining party
          informing that person that it may replace the removed content or cease
          disabling it in 10 business days. Unless the copyright owner files an
          action seeking a court order against the content provider, member or user,
          the removed content may be replaced, or access to it restored, in 10 to 14
          business days or more after receipt of the counter-notice, at our sole discretion.
        </p>

        <p id="copyright-p6">
          The fastest method for delivering your complaint is through our dedicated
          email <a href="mailto:copyright@nomadicplanner.com">copyright@nomadicplanner.com</a>. However,
          if you must send your notice by physical mail, you can do that too, but it
          will take <i>substantially</i> longer for us to receive and respond to it.
          If you still wish to mail us your notice, our physical address for doing so
          is:
        </p>

        <p id="copyright-p7">
          FREE COFFEE INC<br/>
          3302 E WASHINGTON AVE<br/>
          MADISON, WI 53704 USA
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Copyright
