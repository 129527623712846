/**
 * Dependencies
 */

import React from 'react'
import { EventsShowStyle } from './styles/index'

/**
 * Define view
 */

function EventsShow() {
  return (
    <EventsShowStyle>
      <div className="row mx-auto">
        <div className="col-12">
          EventsShow
        </div>
      </div>
    </EventsShowStyle>
  )
}

/**
 * Export view
 */

export default EventsShow
