/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { HomeStyle } from '../users/styles/index'
import { NoMatchStyle } from './styles/index'
import { HomeNavbar } from '../../components/Home/index'

/**
 * Define view
 */

function NoMatch() {
  return (
    <NoMatchStyle>
      <HomeStyle>
        <header className="bg-light">
          <HomeNavbar />
        </header>
      </HomeStyle>

      <div className="container">
        <div className="row mx-auto">
          <div className="col-12">
            <div className="alert alert-danger my-4" role="alert">
              Page Not Found
            </div>

            <Link to="/home" className="btn btn-md btn-primary">Return to home</Link>
          </div>
        </div>
      </div>
    </NoMatchStyle>
  )
}

/**
 * Export view
 */

export default NoMatch
