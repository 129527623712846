/**
 * Dependencies
 */

import styled from 'styled-components'

/**
 * Define styles
 */

const PrivacyStyle = styled.div`
`

/**
 * Export styles
 */

export default PrivacyStyle
