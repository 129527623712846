/**
 * Dependencies
 */

import React from 'react'
import { PrivateRoute } from './helpers/index'
import { Profile, ErrorBoundary } from '../views/index'
import uuid from 'uuid'

/**
 * Define router
 */

const ProfilesRouter = [
  <PrivateRoute key={uuid.v4()} path='/nomad/:username' component={Profile} errorBoundary={ErrorBoundary} />,
]

/**
 * Export router
 */

export default ProfilesRouter
