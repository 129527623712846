/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Termination() {
  return (
    <>
      <section id="termination" className="my-5">
        <h4 className="my-3">
          Termination
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            You can deactivate your account at any time. However some provisions
            persist after the termination of this Agreement.
          </div>
        </div>

        <p id="termination-p1">
          You can stop using our Service at any time for any reason (or no reason),
          and you don't have to give us notice. We also reserve the right to suspend
          or end the Service at any time at our discretion and without notice.
        </p>

        <p id="termination-p2">
          We may also terminate or suspend your access to the Service at any time if
          you are not complying with the letter or spirit of these Terms or if you
          are using the Service in a manner that we believe may cause us financial or
          legal liability.
        </p>

        <p id="termination-p3">
          If your account is closed for any reason, you will no longer have access
          to Content associated with your account. To preserve the integrity of our
          databases, we may retain information submitted by Users for an indefinite
          length of time as necessary to comply with our legal obligations, resolve
          disputes and enforce our agreements. If required by law, we will delete
          personal information by erasing it from our database. However, you understand
          that any termination of your account may involve deletion of any content
          stored in your account for which Nomadic Planner will have no liability whatsoever.
        </p>

        <p id="termination-p4">
          Termination does not immediately relieve you of obligations incurred by you
          under this Agreement. All provisions of this agreement which by their nature
          should survive termination will survive termination of an account.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Termination
