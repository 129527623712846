/**
 * Dependencies
 */

import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import uuid from 'uuid'

/**
 * Define route
 */

const PrivateRoute = ({ component: Component, errorBoundary: ErrorBoundary, path, exact }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const auth_user = firebase.currentUser()

  function clearUser() {
    localStorage.removeItem('user')
    firebase.logout()
    setUser(null)
  }

  firebase.auth.onAuthStateChanged(auth_user => {
    if (auth_user) {
      if (!user) {
        const queryRef = firebase.db.collection('users').where('uid', '==', auth_user.uid).limit(1)

        queryRef.get()
          .then(snapshot => {
            if (!snapshot) {
              console.error('No user found')
              clearUser()
            } else {
              snapshot.forEach(user => {
                let firebase_user = Object.assign({}, user.data(), {id: user.id})
                localStorage.setItem('user', JSON.stringify(firebase_user))
                setUser(firebase_user)
              })
            }
          })
          .catch(err => {
            console.error('PrivateRouteError', err)
            clearUser()
          })
      } else {
        if (user.uid !== auth_user.uid) clearUser()
      }
    } else {
      clearUser()
    }
  })

  if (!auth_user && !user) return <Redirect to="/users/login" />

  if (exact) {
    return <Route key={uuid.v4()} exact path={path} render={props => (
      <UserContext.Provider value={user}>
        <ErrorBoundary>
          <Component {...props} setUser={setUser} />
        </ErrorBoundary>
      </UserContext.Provider>
    )} />
  } else {
    return <Route key={uuid.v4()} path={path} render={props => (
      <UserContext.Provider value={user}>
        <ErrorBoundary>
          <Component {...props} setUser={setUser} />
        </ErrorBoundary>
      </UserContext.Provider>
    )} />
  }
}

/**
 * Export route
 */

export default PrivateRoute
