/**
 * Dependencies
 */

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'

/**
 * Constants
 */

const config = {
  apiKey: "AIzaSyA9V6YIYOufMqLx-GqTahghzmE9nh75qqg",
  authDomain: "knowmadic-97976.firebaseapp.com",
  databaseURL: "https://knowmadic-97976.firebaseio.com",
  projectId: "knowmadic-97976",
  storageBucket: "knowmadic-97976.appspot.com",
  messagingSenderId: "816868831148",
  appId: "1:816868831148:web:645ebf8a5f73776a"
}

/**
 * Define helper
 */

class Firebase {
  constructor() {
    firebase.initializeApp(config)
    this.auth = firebase.auth()
    this.storage = firebase.storage()
    this.db = firebase.firestore()
    this.functions = firebase.functions()
    this.providers = {
      email: firebase.auth.EmailAuthProvider,
      google: firebase.auth.GoogleAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      github: firebase.auth.GithubAuthProvider,
    }
  }

  authListener() {
    return this.auth.onAuthStateChanged()
  }

  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#create-user-with-email-and-password
  register(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  logout() {
    localStorage.removeItem('user')
    return this.auth.signOut()
  }

  currentUser() {
    return this.auth.currentUser
  }

  emailVerified() {
    return this.auth.currentUser ? this.auth.currentUser.emailVerified : null
  }

  sendEmailVerification() {
    return this.auth.currentUser ? this.auth.currentUser.sendEmailVerification() : null
  }

  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#send-password-reset-email
  sendPasswordResetEmail(email) {
    return this.auth.sendPasswordResetEmail(email)
  }

  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#confirm-password-reset
  confirmPasswordReset(code, new_password) {
    return this.auth.confirmPasswordReset(code, new_password)
  }

  updatePassword(password) {
    return this.auth.currentUser ? this.auth.currentUser.updatePassword(password) : null
  }

  googleSignIn(popup_or_redirect = 'popup') {
    const provider = this.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
    this.auth.useDeviceLanguage() //this.auth.languageCode = 'en'

    if (popup_or_redirect === 'popup') {
      return this.auth.signInWithPopup(provider)
    } else {
      return this.auth.signInWithRedirect(provider)
    }
    //=> result.credential.accessToken
    //=> result.user
  }

  get_user(email) {
    return this.auth.getUserByEmail(email)
  }

  userDocument() {
    return this.auth.currentUser() ? this.db.collection('users').doc(this.auth.currentUser().uid) : null
  }

  async updateUserDocument({ uid, email, displayName, photoURL }) {
    const userDoc = await this.db.collection('users').doc(uid)

    const data = {
      email,
      displayName,
      photoURL
    }

    return userDoc.set(data, { merge: true })
  }

  async grant_role(email, role) {
    const user = await this.auth.getUserByEmail(email)
    return this.auth.setCustomUserClaims(user.uid, {
      isAdmin: true
    })
  }
}

/**
 * Export helper
 */

export default new Firebase()
