/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SettingsStyle } from './styles/index'
import { HomeStyle } from '../users/styles/index'
import { UserContext } from '../../contexts/index'
import { BackToHomeNavbar, BackToLink } from '../../components/Home/index'
import firebase from '../../helpers/firebase'

/**
 * Define view
 */

function Settings(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [profile_username, changeUsername] = useState(userContext.username)
  const [profile_hometown, changeHometown] = useState(userContext.hometown)
  const [profile_website, changeWebsite] = useState(userContext.website)
  const [profile_facebook, changeFacebook] = useState(userContext.facebook)
  const [profile_twitter, changeTwitter] = useState(userContext.twitter)
  const [profile_instagram, changeInstagram] = useState(userContext.instagram)
  const [profile_bio, changeBio] = useState(userContext.bio)

  async function changeLocale(event) {
    event.preventDefault()

    try {
      const new_locale = event.target.value
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ locale: new_locale })

      const new_user = Object.assign({}, userContext, { locale: new_locale })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  async function changePrivacyPref(event) {
    event.preventDefault()

    try {
      const new_privacy_pref = event.target.value
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ privacyPref: new_privacy_pref })

      const new_user = Object.assign({}, userContext, { privacyPref: new_privacy_pref })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  async function changeNotificationsPref(event) {
    event.preventDefault()

    try {
      const new_notifications_pref = event.target.value
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ notificationsPref: new_notifications_pref })

      const new_user = Object.assign({}, userContext, { notificationsPref: new_notifications_pref })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  async function saveUsername(event) {
    event.preventDefault()

    try {
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ username: profile_username })

      const new_user = Object.assign({}, userContext, { username: profile_username })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  async function saveHometown(event) {
    event.preventDefault()

    try {
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ hometown: profile_hometown })

      const new_user = Object.assign({}, userContext, { hometown: profile_hometown })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  async function saveWebsite(event) {
    event.preventDefault()

    try {
      if (profile_website === '' || validator.isURL(profile_website, { protocols: ['http', 'https'], require_protocol: true })) {
        const userRef = await firebase.db.collection('users').doc(userContext.id)
        userRef.update({ website: profile_website })

        const new_user = Object.assign({}, userContext, { website: profile_website })
        props.setUser(new_user)
        localStorage.setItem('user', JSON.stringify(new_user))
      } else {
        alert('Not a valid link. Ensure you copy the full path.')
      }
    } catch(err) {
      console.error(err)
    }
  }

  async function saveFacebook(event) {
    event.preventDefault()

    try {
      if (profile_facebook === '' || validator.isURL(profile_facebook, { protocols: ['http', 'https'], require_protocol: true })) {
        const userRef = await firebase.db.collection('users').doc(userContext.id)
        userRef.update({ facebook: profile_facebook })

        const new_user = Object.assign({}, userContext, { facebook: profile_facebook })
        props.setUser(new_user)
        localStorage.setItem('user', JSON.stringify(new_user))
      } else {
        alert('Not a valid link. Ensure you copy the full path.')
      }
    } catch(err) {
      console.error(err)
    }
  }

  async function saveTwitter(event) {
    event.preventDefault()

    try {
      if (profile_twitter === '' || validator.isURL(profile_twitter, { protocols: ['http', 'https'], require_protocol: true })) {
        const userRef = await firebase.db.collection('users').doc(userContext.id)
        userRef.update({ twitter: profile_twitter })

        const new_user = Object.assign({}, userContext, { twitter: profile_twitter })
        props.setUser(new_user)
        localStorage.setItem('user', JSON.stringify(new_user))
      } else {
        alert('Not a valid link. Ensure you copy the full path.')
      }
    } catch(err) {
      console.error(err)
    }
  }

  async function saveInstagram(event) {
    event.preventDefault()

    try {
      if (profile_instagram === '' || validator.isURL(profile_instagram, { protocols: ['http', 'https'], require_protocol: true })) {
        const userRef = await firebase.db.collection('users').doc(userContext.id)
        userRef.update({ instagram: profile_instagram })

        const new_user = Object.assign({}, userContext, { instagram: profile_instagram })
        props.setUser(new_user)
        localStorage.setItem('user', JSON.stringify(new_user))
      } else {
        alert('Not a valid link. Ensure you copy the full path.')
      }
    } catch(err) {
      console.error(err)
    }
  }

  async function saveBio(event) {
    event.preventDefault()

    try {
      const userRef = await firebase.db.collection('users').doc(userContext.id)
      userRef.update({ bio: profile_bio })

      const new_user = Object.assign({}, userContext, { bio: profile_bio })
      props.setUser(new_user)
      localStorage.setItem('user', JSON.stringify(new_user))
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (userContext) setIsLoading(false)
    }, 600)
  }, [])

  return (
    <SettingsStyle>
      <HomeStyle>
        <header className="bg-light">
          <BackToHomeNavbar />
        </header>

        {isLoading ? <LinearProgress /> : null}
      </HomeStyle>

      <main className="bg-white shadow py-4">
        <div className="container">
          <BackToLink url="/home" text="Home" />

          <section>
            <div className="row border-bottom mt-3 mb-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-8 col-sm-9">
                    <h3 id="h3-settings">Settings</h3>
                  </div>

                  <div className="col-4 col-sm-3 text-right d-flex justify-content-end align-items-end mb-2">
                    <Link to="/users/signout" className="text-muted align-bottom">
                      Sign out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="row">
              <div className="col py-2 mt-1">

                <a id="a-users-settings-support" href="mailto:support@nomadicplanner.com" style={{"textDecoration":"none"}}>

                  <div className="card">
                    <div className="card-body bg-white">

                      <div className="row">
                        <div className="col">
                          <i className="far fa-life-ring"></i>
                          <b>Guides and Support</b>
                        </div>

                        <div className="col-auto text-right">
                          <i className="fas fa-chevron-right"></i>
                        </div>

                      </div>
                    </div>
                  </div>
                </a>

              </div>
            </div>
          </section>

          <section>
            <div className="row">
              <div className="col-12 py-2 mt-1">
                <div className="form-group">
                  <label htmlFor="input-username"><b>Username</b></label>
                  <input id="input-username" className="form-control my-1" type="text" name="username" value={profile_username} onChange={e => changeUsername(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveUsername}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-hometown"><b>Hometown</b></label>
                  <input id="input-hometown" className="form-control my-1" type="text" name="hometown" value={profile_hometown} onChange={e => changeHometown(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveHometown}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-website"><b>Website</b></label>
                  <input id="input-website" className="form-control my-1" type="url" name="website" value={profile_website} onChange={e => changeWebsite(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveWebsite}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-facebook"><b>Facebook Link</b></label>
                  <input id="input-facebook" className="form-control my-1" type="url" name="facebook" value={profile_facebook} onChange={e => changeFacebook(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveFacebook}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-twitter"><b>Twitter Link</b></label>
                  <input id="input-twitter" className="form-control my-1" type="url" name="twitter" value={profile_twitter} onChange={e => changeTwitter(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveTwitter}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-instagram"><b>Instagram Link</b></label>
                  <input id="input-instagram" className="form-control my-1" type="url" name="instagram" value={profile_instagram} onChange={e => changeInstagram(e.target.value)}></input>
                  <button className="btn btn-sm btn-secondary" onClick={saveInstagram}>Save</button>
                </div>

                <div className="form-group">
                  <label htmlFor="input-bio"><b>Bio</b></label>
                  <textarea className="form-control my-1" id="input-bio" rows="4" value={profile_bio} onChange={e => changeBio(e.target.value)}></textarea>
                  <button className="btn btn-sm btn-secondary" onClick={saveBio}>Save</button>
                </div>
              </div>
            </div>
          </section>

          {true ? null :
            <section>
              <div className="row">
                <div className="col-12 py-2">

                  <label id="label-switch-locale" htmlFor="select-settings-locale" className="w-100"><b>Switch locale</b></label>

                  <div className="form-group m-0">
                    <select id="select-settings-locale" className="form-control" name="locale" aria-labelledby="label-switch-locale"
                            onChange={changeLocale} value={userContext.locale}>
                      <option id="select-settings-locale-en" value="en">
                        English
                      </option>
                      <option id="select-settings-locale-es" value="es">
                        Español
                      </option>
                    </select>
                  </div>

                </div>
              </div>
            </section>
          }

          {isLoading ? null :
            <section>
              <div className="row">
                <div className="col-12 py-2 mt-1">

                  <label id="label-privacy-pref" htmlFor="select-privacy-pref" className="w-100"><b>Privacy Preference</b></label>

                  <div className="form-check">
                    <input className="form-check-input switch-privacy-pref" type="radio" name="privacy_pref" id="privacy-public"
                           value="public" checked={userContext.privacyPref === 'public'}
                           onChange={changePrivacyPref}></input>
                    <label className="form-check-label" htmlFor="privacy-public">
                      Public: anyone can look up my account in the app.
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input switch-privacy-pref" type="radio" name="privacy_pref" id="privacy-private"
                           value="private" checked={userContext.privacyPref === 'private'}
                           onChange={changePrivacyPref}></input>
                    <label className="form-check-label" htmlFor="privacy-private">
                      Private: people can only invite me via direct email.
                    </label>
                  </div>

                </div>
              </div>
            </section>
          }

          {true ? null :
            <section>
              <div className="row">
                <div className="col-12 py-2 mt-1">

                  <label id="label-notification-pref" htmlFor="select-notification-pref" className="w-100"><b>Notifications</b></label>

                  <div className="form-check">
                    <input className="form-check-input switch-notifications-pref" type="radio" name="ondemand" id="notification-ondemand" value="ondemand" checked={userContext.notificationsPref === 'ondemand'} onChange={changeNotificationsPref}></input>
                    <label className="form-check-label" htmlFor="notification-ondemand">
                      On-demand
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input switch-notifications-pref" type="radio" name="daily" id="notification-daily" value="daily" checked={userContext.notificationsPref === 'daily'} onChange={changeNotificationsPref}></input>
                    <label className="form-check-label" htmlFor="notification-daily">
                      Daily summaries
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input switch-notifications-pref" type="radio" name="weekly" id="notification-weekly" value="weekly" checked={userContext.notificationsPref === 'weekly'} onChange={changeNotificationsPref}></input>
                    <label className="form-check-label" htmlFor="notification-weekly">
                      Weekly summaries
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input switch-notifications-pref" type="radio" name="off" id="notification-off" value="off" checked={userContext.notificationsPref === 'off'} onChange={changeNotificationsPref}></input>
                    <label className="form-check-label" htmlFor="notification-off">
                      Off
                    </label>
                  </div>

                </div>
              </div>
            </section>
          }

          <section>
            <div className="row">
              <div className="col-12 py-2 mt-2">

                <div className="card">
                  <div className="card-body bg-light border border-danger">
                    <h5 id="h5-deactivate">
                      Deactivate your account
                    </h5>

                    <hr/>

                    <p>
                      If you want to deactivate your account, you can do so from the
                      <Link to="/users/deactivate-account" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                        Deactivate Account
                      </Link>
                      Page
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
      </main>
    </SettingsStyle>
  )
}

/**
 * Export view
 */

export default Settings
