/**
 * Dependencies
 */

import styled from 'styled-components'

/**
 * Define styles
 */

const NoMatchStyle = styled.div`
`

/**
 * Export styles
 */

export default NoMatchStyle
