/**
 * Dependencies
 */

import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function InviteFriendDialog(props) {
  return (
    <Dialog open={props.open}
            onClose={props.onClose}
            fullWidth={true}
            aria-labelledby="dialog-email-invite">
      {(props.friends.length === 0) ?
          <DialogTitle id="form-dialog-title">Don't see your friends?</DialogTitle>
        : <DialogTitle id="form-dialog-title">Friend Invite</DialogTitle>}
      {(props.friends.length === 0) ?
        <DialogContent><Link to="/users/search" className="btn btn-md btn-block btn-outline-primary p-3"><strong>Try searching</strong></Link></DialogContent>
        : <DialogContent>
          <Select id="input-email"
              label="Email Address"
              value={props.emailToInvite}
              onChange={(e) => props.setEmailToInvite(e.target.value)}
              fullWidth >
            <option value="" />
            {props.friends.map((friend, index) => <option key={index} value={friend}>{friend}</option>)}
          </Select>
        </DialogContent>}
      {(props.friends.length === 0) ? null :
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.sendEmailInvite} color="primary">
            Send
          </Button>
        </DialogActions>}
    </Dialog>
  )
}

/**
 * Export component
 */

export default InviteFriendDialog
