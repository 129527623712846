/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function UserContent() {
  return (
    <>
      <section id="user-content" className="my-5">
        <h4 className="my-4">
          User-Generated Content
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            From the first time you setup your account you control how much information
            is collected, stored shared privately, and shared publicly. Keep your travel
            plans ‘safe’ with what that means to you personally. We believe sharing the
            city and dates of travel is safe, but sharing the exact addresses and times
            may be something a user deems “Too invasive.”

            You own Content you create, but you allow us certain rights to it, so
            that we can display and share the Content you post. You still have control
            over your Content, and responsibility for it, and the rights you grant us
            are limited to those we need to provide the Service. We have the right to
            remove Content or close accounts if we need to.
          </div>
        </div>

        <p>
          You may create User-Generated Content while using the Service. You are
          solely responsible for the Content of, and any harm resulting from, any
          User-Generated Content that you post, upload, link to or otherwise make
          available via the Service, regardless of the form of that Content. We are
          not responsible for any public display or misuse of your User-Generated
          Content.
        </p>

        <p>
          You retain ownership of and responsibility for Content you create. If
          you're posting anything you did not create yourself, you agree that you
          will only submit Content that you have the right to post, and you will
          fully comply with any third party licenses relating to Content you post.
        </p>

        <p>
          We have the right (though not the obligation) to refuse or remove any
          User-Generated Content that, in our sole discretion, violates any Nomadic Planner
          terms or policies.
        </p>

        <p>
          Your Content belongs to you, and you are responsible for Content you post
          even if it does not belong to you. However, we need the legal right to do
          things like host it, publish it, and share it. You grant us and our legal
          successors the right to store and display your Content and make incidental
          copies as necessary to render the Website and provide the Service. That
          means you're giving us the right to do things like reproduce your content
          (so we can do things like copy it to our database and make backups); display
          it (so we can do things like show it to you and other users); modify it
          (so our server can do things like parse it into a search index); distribute
          it (so we can do things like share it with other users); and perform it
          (in case your content is an audio file). This license does not grant Nomadic Planner
          the right to sell your Content or otherwise distribute it outside of our
          Service.
        </p>

        <p>
          You retain all moral rights to Content you upload, publish, or submit to
          any part of the Service, including the rights of integrity and attribution.
          However, you waive these rights and agree not to assert them against us,
          to enable us to provide the Service, but not otherwise. You understand
          that you will not receive any payment for any of the rights granted in
          this Section. To the extent such an agreement is not enforceable by
          applicable law, you grant Nomadic Planner a nonexclusive, revocable, worldwide,
          royalty-free right to (1) use the Content without attribution strictly as
          necessary to render the Website and provide the Service; and (2) make
          reasonable adaptations of the Content as provided in this Section. We
          need these rights to allow basic functions like search to work.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default UserContent
