/**
 * Dependencies
 */

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { AdminTripsStyle } from './styles/index'
import { HomeNavbar, HomeDashboardLink } from '../../components/Home/index'
import firebase from '../../helpers/firebase'

/**
 * Define view
 */

function AdminTrips() {
  const [isLoading, setIsLoading] = useState(true)
  const [trips, setTrips] = useState([])

  useEffect(() => {
    async function fetchTrips() {
      const tripsRef = await firebase.db.collection('trips').orderBy('created_at', 'desc')
      const tripsSnapshot = await tripsRef.get()

      setTrips(tripsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id})))
      setIsLoading(false)
    }

    fetchTrips()
  }, [])

  return (
    <AdminTripsStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      <main className="bg-white shadow pt-2 pb-5">
        <HomeDashboardLink />

        <div className="container">
          <div className="row mt-5">
            <div className="col-12">
              <h3>Admin Dashboard</h3>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <Link to="/admin/users" className="btn btn-lg btn-block btn-outline-secondary m-2">Users</Link>
            </div>

            <div className="col-4">
              <Link to="/admin/trips" className="btn btn-lg btn-block btn-secondary m-2">Trips</Link>
            </div>

            <div className="col-4">
              <Link to="/admin/events" className="btn btn-lg btn-block btn-outline-secondary m-2">Events</Link>
            </div>
          </div>

          {isLoading ? null :
            <div className="row mt-5">
              <div className="col-12">

                <a href="https://console.firebase.google.com/u/3/project/knowmadic-97976/database/firestore/data~2Ftrips"
                   className="btn btnmd btn-primary mb-4" target="_blank">Manage Trips</a>

                <ul>
                  {trips && trips.map((trip, index) => {
                    return (
                      <li key={trip.id}>
                        {trip.title}
                      </li>
                    )
                  })}
                </ul>

              </div>
            </div>
          }
        </div>
      </main>
    </AdminTripsStyle>
  )
}

/**
 * Export view
 */

export default AdminTrips
