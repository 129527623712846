/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function AdminDashboardLink(props) {
  if (!props.isAdmin) {
    return null
  }

  return (
    <div className="row">
      <div className="col-12 bg-warning py-2">
        <div className="container">
          <Link to="/admin" className="btn btn-md btn-light mr-3">
            Admin Dashboard
          </Link>
          <b>*Only administrators can see this</b>
        </div>
      </div>
    </div>
  )
}

/**
 * Export component
 */

export default AdminDashboardLink
