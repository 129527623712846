/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { UsersSearchStyle, HomeStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import {
  HomeNavbar,
  AdminDashboardLink,
  HomeControls,
  UserList,
  FriendList,
} from '../../components/Home/index'

/**
 * Define view
 */

function UsersSearch(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [friends, setFriends] = useState([])
  const [emailSearch, setEmailSearch] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    if (userContext) fetchUsers()
  }, [])

  async function fetchUsers() {
    setIsLoading(true)

    /**
     * Fetch users
     */

    const usersRef = await firebase.db.collection('users')
      .where('privacyPref', '==', 'public')
      .orderBy('email', 'desc')
    const usersSnapshot = await usersRef.get()

    let objUsers = usersSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
    objUsers = objUsers.filter(user => user.email != userContext.email)

    /**
     * Fetch accepted friend requests
     */

    const requestsRef = await firebase.db.collection('friend_requests')
      .where('sender_uid', '==', userContext.uid)
      .orderBy('accepted_at', 'desc')
    const requestsSnapshot = await requestsRef.get()

    let objRequests = requestsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
    objRequests = objRequests.filter(request => request.accepted_at != null)
    const accepted_friend_requests = objRequests.map(request => request.receiver_email)

    /**
     * Filter friends out of master user list
     */

    const myFriends = objUsers.filter(user => accepted_friend_requests.includes(user.email))
    objUsers = objUsers.filter(user => !accepted_friend_requests.includes(user.email))

    setUsers(objUsers)
    setFriends(myFriends)
    setIsLoading(false)
  }

  async function fetchFilteredUsers(filter) {
    setIsLoading(true)

    const usersRef = await firebase.db.collection('users')
      .where('privacyPref', '==', 'public')
    const usersSnapshot = await usersRef.get()

    let objUsers = usersSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
    // Filter public users by query
    objUsers = objUsers.filter(user => user.email.match(filter))

    // Filter out current user
    objUsers = objUsers.filter(user => user.email != userContext.email)

    setUsers(objUsers)
    setIsFiltered(true)
    setIsLoading(false)
  }

  function handleUserSearch(event) {
    event.preventDefault()
    fetchFilteredUsers(emailSearch)
  }

  function removeFilter(event) {
    event.preventDefault()
    setIsFiltered(false)
    setEmailSearch('')
    fetchUsers()
  }

  return (
    <UsersSearchStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      {isLoading ? null :
        <main className="bg-white shadow pt-2 pb-5">
          <div className="container">
            <FriendList friends={friends} />

            <h3>Search all users</h3>

            {isFiltered ?
              <button onClick={removeFilter} className="btn btn-secondary">Lookup: {emailSearch} (click to remove)</button> :
              <form onSubmit={handleUserSearch}>
                <div className="form-group">
                  <input id="input-search" type="text" className="form-control"
                         value={emailSearch} onChange={e => setEmailSearch(e.target.value)}
                         placeholder="Type filter here (search by email)" required></input>
                </div>
                <button type="submit" className="btn btn-primary">Search</button>
              </form>
            }

            <UserList users={users} isFiltered={isFiltered} />
          </div>
        </main>
      }
    </UsersSearchStyle>
  )
}

/**
 * Export view
 */

export default UsersSearch
