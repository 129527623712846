/**
 * Dependencies
 */

import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Switch } from 'react-router-dom'
import client from './store/client'
import {
  RootRouter,
  UsersRouter,
  TripsRouter,
  EventsRouter,
  AdminRouter,
  ProfilesRouter,
  NoMatchRouter,
} from './routes/index'

/**
 * Import global styles
 */

import './App.scss'

/**
 * Define component
 */

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          {RootRouter}
          {UsersRouter}
          {TripsRouter}
          {EventsRouter}
          {ProfilesRouter}
          {AdminRouter}
          {NoMatchRouter}
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  )
}

/**
 * Export component
 */

export default App
