/**
 * Dependencies
 */

import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import uuid from 'uuid'
import * as firebaseui from 'firebaseui'
import { LoginStyle } from './styles/index'
import { Card } from '../../components/Grouping/index'
import firebase from '../../helpers/firebase'

/**
 * Constants
 */

const uiConfig = {
  signInSuccessUrl: '/home',
  signInOptions: [
    firebase.providers.email.PROVIDER_ID,
    firebase.providers.google.PROVIDER_ID,
    firebase.providers.facebook.PROVIDER_ID,
  ],
  tosUrl: '/terms',
  privacyPolicyUrl: '/privacy'
}
const ui = new firebaseui.auth.AuthUI(firebase.auth)

/**
 * Define view
 */

function Login(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  console.log('Login.user', user)

  function clearUser() {
    localStorage.removeItem('user')
    firebase.logout()
    setUser(null)
  }

  useEffect(() => {
    firebase.auth.onAuthStateChanged(auth_user => {
      if (auth_user) {
        if (!user) {
          const queryRef = firebase.db.collection('users').where('uid', '==', auth_user.uid).limit(1)

          queryRef.get()
            .then(snapshot => {
              if (!snapshot || snapshot.empty) {
                firebase.db.collection('users').doc(uuid.v4()).set({
                  uid: auth_user.uid,
                  email: auth_user.email,
                  isEmailVerified: true,
                  isBlocked: false,
                  locale: 'en',
                  notificationsPref: 'daily',
                  privacyPref: 'public',
                  created_at: new Date(),
                })

                props.history.push('/home')
              } else {
                snapshot.forEach(user => {
                  let firebase_user = Object.assign({}, user.data(), {id: user.id})
                  localStorage.setItem('user', JSON.stringify(firebase_user))
                  setUser(firebase_user)
                })
              }
            })
            .catch(err => {
              clearUser()
            })
        } else {
          if (user.uid !== auth_user.uid) clearUser()
        }
      } else {
        clearUser()
      }
    })

    if (!user) ui.start('#firebaseui-auth-container', uiConfig)
  })

  if (user) return <Redirect to="/home" />

  return (
    <LoginStyle>
      <div className="row justify-content-center">
        <div className="col-11 col-lg-8 mt-5">

          <Card>
            <h3>Nomadic Planner</h3>

            <div id="firebaseui-auth-container"></div>
          </Card>

        </div>
      </div>
    </LoginStyle>
  )
}

/**
 * Export view
 */

export default Login
