/**
 * Dependencies
 */

import React from 'react'
import { TermStyle } from './styles/index'
import { Navbar, Footer } from '../../components/Landing/index'
import {
  Introduction,
  Defenitions,
  Accounts,
  HistoricalAccounts,
  Communications,
  AcceptableUse,
  OtherWebsites,
  UserContent,
  Payments,
  Copyright,
  IntellectualProperty,
  Termination,
  WarrantyDisclaimer,
  LimitedLiability,
  Indemnification,
  Disputes,
  EntireAgreement,
} from '../../components/Term/index'

/**
 * Define view
 */

function Term() {
  return (
    <TermStyle>
      <header>
        <div className="container border-right border-bottom border-left">
          <Navbar />
        </div>

        <div id="start-of-content"></div>
      </header>

      <main>
        <div className="container">

          <div className="row">
            <div className="col-12">
              <h1 id="h1-mktg-terms" className="mt-5">Terms of Use</h1>

              <p id="terms-last-updated" className="font-weight-bold">
                Last Updated: May 3, 2019
                <br/>
                Effective Date: May 3, 2019
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">

              <article id="terms-of-service">
                <Introduction />
                <Defenitions />
                <Accounts />
                <HistoricalAccounts />
                <Communications />
                <AcceptableUse />
                <OtherWebsites />
                <UserContent />
                <Payments />
                <Copyright />
                <IntellectualProperty />
                <Termination />
                <WarrantyDisclaimer />
                <LimitedLiability />
                <Indemnification />
                <Disputes />
                <EntireAgreement />
              </article>

            </div>
          </div>

        </div>
      </main>

      <Footer />
    </TermStyle>
  )
}

/**
 * Export view
 */

export default Term
