/**
 * Dependencies
 */

import React from 'react'
import { CardStyle } from './styles/index'

/**
 * Define component
 */

function Card(props) {
  return (
    <CardStyle>
      {props.children}
    </CardStyle>
  )
}

/**
 * Export component
 */

export default Card
