/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Cookies() {
  return (
    <>
      <section id="cookies-usage" className="my-5">
        <h4 className="my-4">
          Our Use Of Cookies
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            Cookies are essential for using the Service.
          </div>
        </div>

        <p id="cookies-usage-p1">
          Nomadic Planner uses cookies to make interactions with our Service easy and meaningful.
          We use cookies to keep you logged in and remember your preferences.
        </p>

        <p id="cookies-usage-p2">
          A cookie is a small piece of text that our web server stores on your computer
          or mobile device, which your browser sends to us when you return to our Site.
          Cookies do not necessarily identify you if you are merely visiting Nomadic Planner;
          however, a cookie may store a unique identifier for each logged in User. The
          cookies Nomadic Planner sets are essential for the operation of the Website, or are
          used for performance or functionality. By using our Website, you agree that
          we can place these types of cookies on your computer or device. If you
          disable your browser or device's ability to accept cookies, you will not be
          able to log in or use Nomadic Planner's Services.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Cookies
