/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Define component
 */

function BackToLink(props) {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Link id="link-back" className="py-4 pr-5" to={props.url}>
          <button className="btn btn-outline-secondary btn-back" style={{"minWidth": "85px"}}>
            <div className="row">
              <div className="col pr-0"><i className="fas fa-angle-left d-flex h-100 align-items-center"></i></div>
              <div className="col pl-2">{props.text}</div>
            </div>
          </button>
        </Link>
      </div>
    </div>
  )
}

/**
 * Export component
 */

export default BackToLink
