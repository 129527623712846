/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function DataTransfers() {
  return (
    <>
      <section id="data-transfers" className="my-5">
        <h4 className="my-4">
          International Data Transfer
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            International Users must agree to have data transferred to the U.S.
          </div>
        </div>

        <p id="data-transfers-p1">
          Nomadic Planner is a worldwide service. By using our products or services, you
          authorize us to transfer and store your information outside of your home
          country, including in the United States, for the purposes described in this
          policy. The privacy protections and the rights of authorities to access your
          personal information in such countries may not be equivalent to those in
          your home country.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default DataTransfers
