/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { FooterStyle } from './styles/index'

/**
 * Define component
 */

function Footer() {
  return (
    <FooterStyle>
      <footer>
        <div className="container py-4">
          <hr/>

          <div className="row">
            <div className="col-12">
              <p className="text-muted pl-3">
                <small>
                  Copyright {new Date().getFullYear()} Free Coffee Inc. All rights reserved.
                  <Link to="/privacy" className="text-muted">Privacy</Link>.
                  <Link to="/terms" className="text-muted">Terms</Link>.
                </small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </FooterStyle>
  )
}

/**
 * Export component
 */

export default Footer
