/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Introduction() {
  return (
    <>
      <div className="card text-white bg-primary my-3">
        <div className="card-block p-3">
          The Terms of Use page is no place for humor and entertainment, which is something our founder finds very valuable in life. Knowing that our users are very busy we have provided summaries for convenience only and are not legally binding. We've done our best to offer clear and simple explanations of what everything means. At the core, we have to collect data that users input to create a valuable information hub. We have found inspiration from how Spotify runs their data collection, though the data they collect could be sold to third parties they primarily use the data internally to create better music suggestions.
        </div>
      </div>

      <p id="terms-of-service-p1">
        These Terms of Service was written in English (US). To the extent any translated
        version of this agreement conflicts with the English version, the English
        version will control.
      </p>

      <p id="terms-of-service-p2">
        These Terms of Service govern your use of the Nomadic Planner Website and Service.
        Nomadic Planner's collection and use of personal information in connection with the
        Services is described in Nomadic Planner's Privacy Policy located at
        <a href="https://www.nomadicplanner.com/privacy">www.nomadicplanner.com/privacy</a>.
      </p>

      <p id="terms-of-service-p3">
        We may modify this Agreement at any time, but we will give you 30 days
        of notice before changes take effect, either by notifying you on the Site or
        by sending you an email. By using the Service after that effective date, you
        agree to the new Terms. If you don't agree to them, you should delete your
        account before they take effect, otherwise your use of the Service will be
        subject to the new Terms.
      </p>

      <p id="terms-of-service-p4">
        Please read this Agreement carefully before accessing or using our Services.
        <b>You may not access or use any Services unless you agree to abide by all of
        the terms and conditions in this Agreement.</b> If you do not understand all
        of the terms of this Agreement, please ask for support at
        <a href="/term">help.nomadicplanner.com</a>
        before using the Services.
      </p>
    </>
  )
}

/**
 * Export component
 */

export default Introduction
