/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Defenitions() {
  return (
    <>
      <section id="definitions" className="my-5">
        <h4 className="my-4">
          Definitions
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We use these terms throughout the agreement, and they have specific
            meanings.
          </div>
        </div>

        <p id="definitions-p1">
          The "Agreement" refers, collectively, to all the terms, conditions, notices
          contained or referenced in this document (the "Terms of Service" or the "Terms")
          and all other operating rules, policies (including the Privacy Policy,
          available at <a href="https://www.nomadicplanner.com/privacy">www.nomadicplanner.com/privacy</a>)
          and procedures that we may publish from time to time on the Website.
        </p>

        <p id="definitions-p2">
          The "Service" refers to the applications, software, products, third parties
          we pull data from (such as tweets) and services provided by Nomadic Planner.
        </p>

        <p id="definitions-p3">
          The "Website" refers to Nomadic Planner's website (or "Site") located at
          <a href="https://www.nomadicplanner.com">nomadicplanner.com</a>, and all content, services,
          and products provided by Nomadic Planner at or through the Website. It also refers
          to all Nomadic Planner-owned subdomains of nomadicplanner.com.
        </p>

        <p id="definitions-p4">
          The "User," "You," and "Your" refer to the individual person that has
          visited or is using the Website or Service; that accesses or uses any
          part of the account; or that directs the use of the account in the
          performance of its functions. A User must be at least 18 years of age.
        </p>

        <p id="definitions-p5">
          "Nomadic Planner," "We," and "Us" refer to Free Coffee, Inc., as well as our affiliates,
          directors, subsidiaries, contractors, licensors, officers, agents, and employees.
        </p>

        <p id="definitions-p6">
          “Content” refers to content featured or displayed through the Website,
          including without limitation text, data, articles, images, photographs,
          graphics, software, applications, designs, features, and other materials
          that are available on the Website or otherwise available through the
          Service. "Content" also includes Services. “User-Generated Content” is
          Content, written or otherwise, created or uploaded by our Users.
          “Paid Content” is Content only available to Users who are participating
          in a payment plan.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Defenitions
