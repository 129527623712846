/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { NotificationsStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'
import {
  HomeNavbar,
  NotificationList,
} from '../../components/Home/index'

/**
 * Define view
 */

function Notifications() {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [invites, setInvites] = useState([])
  const [tripInvites, setTripInvites] = useState([])

  useEffect(() => {
    async function fetchInvites() {
      const invitesRef = await firebase.db.collection('friend_requests')
        .where('receiver_uid', '==', userContext.uid)
        .where('declined_at', '==', null)
      const invitesSnapshot = await invitesRef.get()
      const objInvites = invitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

      const pendingObjInvites = objInvites.filter(invite => invite.accepted_at == null)
      setInvites(pendingObjInvites)

      const tripInvitesRef = await firebase.db.collection('trip_invites')
        .where('receiver_email', '==', userContext.email)
        .where('declined_at', '==', null)
      const tripInvitesSnapshot = await tripInvitesRef.get()
      const objTripInvites = tripInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

      const pendingObjTripInvites = objTripInvites.filter(invite => invite.accepted_at == null)
      console.log('pendingObjTripInvites', pendingObjTripInvites)
      setTripInvites(pendingObjTripInvites)

      setIsLoading(false)
    }

    if (userContext) fetchInvites()
  }, [])

  return (
    <NotificationsStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      {isLoading ? null :
        <main className="bg-white shadow pt-2 pb-5">
          <div className="container">
            <h3 className="mt-2">Notifications</h3>

            <NotificationList invites={invites} tripInvites={tripInvites} />
          </div>
        </main>
      }
    </NotificationsStyle>
  )
}

/**
 * Export view
 */

export default Notifications
