/**
 * Dependencies
 */

import styled from 'styled-components'

/**
 * Define styles
 */

const CardStyle = styled.div`
  border: 1px solid #DDD;
  border-radius: 5px;
  padding: 20px;
`

/**
 * Export styles
 */

export default CardStyle
