/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { HomeNavbarStyle } from './styles/index'
import { UserContext } from '../../contexts/index'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function HomeNavbar() {
  const userContext = useContext(UserContext)
  const [invites, setInvites] = useState([])
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    async function fetchInvites() {
      const invitesRef = await firebase.db.collection('friend_requests')
        .where('receiver_uid', '==', userContext.uid)
        .where('declined_at', '==', null)
      const invitesSnapshot = await invitesRef.get()
      const objInvites = invitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
      const pendingObjInvites = objInvites.filter(invite => invite.accepted_at == null)

      const tripInvitesRef = await firebase.db.collection('trip_invites')
        .where('receiver_email', '==', userContext.email)
        .where('declined_at', '==', null)
        .where('accepted_at', '==', null)
      const tripInvitesSnapshot = await tripInvitesRef.get()
      const objTripInvites = tripInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

      if (pendingObjInvites.length === 0 && objTripInvites.length === 0) {
        setIsPending(false)
      } else {
        setIsPending(true)
      }
    }

    if (userContext) fetchInvites()
  }, [])

  return (
    <HomeNavbarStyle>
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light bg-light px-0">
          <Link id="a-mktg-navbar-brand" className="navbar-brand text-dark" to="/home">
            <h3 className="mb-0"><strong>Nomadic</strong> Planner</h3>
          </Link>

          <button className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar-welcome"
                  aria-label="Navbar Menu">
            <span className="navbar-toggler-icon"></span>
          </button>

          <a className="btn btn-link sr-only sr-only-focusable"
             href="#start-of-content"
             tabIndex="1">
            Skip to main content.
          </a>

          <div className="collapse navbar-collapse" id="navbar-welcome">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item text-center"></li>
            </ul>

            <ul className="navbar-nav">
              <li className="nav-item text-center">
                <Link id="a-users-navbar-users" to="/users/search"
                   className="nav-link alpha-6">
                  <b>Users</b>
                </Link>
              </li>

              <li className="nav-item text-center">
                <Link id="a-users-navbar-notifications" to="/users/notifications"
                   className="nav-link" style={{'minWidth': '85px'}}>
                  { isPending ?
                    <i className="fas fa-bell text-danger anim-shake"></i> :
                    <i className="fas fa-bell text-dark"></i>
                  }
                </Link>
              </li>

              <li className="nav-item text-center">
                <Link id="a-users-navbar-settings" to="/users/settings"
                   className="nav-link alpha-6">
                  <b>Settings</b>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </HomeNavbarStyle>
  )
}

/**
 * Export component
 */

export default HomeNavbar
