/**
 * Dependencies
 */

import React from 'react'

/**
 * Define context
 */

const UserContext = React.createContext(null)

/**
 * Export context
 */

export default UserContext
