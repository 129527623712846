/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { LandingStyle } from './styles/index'
import { Navbar, Footer } from '../../components/Landing/index'

/**
 * Define view
 */

function Landing() {
  return (
    <LandingStyle>
      <header>
        <div className="container border-right border-bottom border-left">
          <Navbar />
        </div>

        <div id="start-of-content"></div>

        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-6 order-12 order-md-1">
              <div className="my-5 pt-5 d-none d-md-block"></div>

              <h1>
                Know where you're going.
              </h1>

              <p className="text-lg">
                A traveler's calendar. Tell us where you're going and we'll tell you who and what is there.
              </p>

              <Link className="btn btn-outline-primary mb-4 px-4 py-2" to="/users/login">Get started</Link>
            </div>

            <div className="col-12 col-md-6 order-1 order-md-12">
              <img alt="Airport" src="https://storage.googleapis.com/nomadicplanner-image-assets/mktg/undraw_airport_2581.png" className="img-fluid"/>
            </div>
          </div>
        </div>
      </header>

      <section id="section-process" className="py-4 bg-light">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 col-md-3 mt-2">
              <h4>Add trips.</h4>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
              <i className="fas fa-arrow-right fa-2x d-none d-md-block"></i>
              <i className="fas fa-arrow-down d-md-none d-block"></i>
            </div>

            <div className="col-12 col-md-4 mt-2">
              <h4>Match friends’ trips.</h4>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
              <i className="fas fa-arrow-right fa-2x d-none d-md-block"></i>
              <i className="fas fa-arrow-down d-md-none d-block"></i>
            </div>

            <div className="col-12 col-md-3 mt-2">
              <h4>See artists & events.</h4>
            </div>
          </div>
        </div>
      </section>

      <main>
        <div className="container">
          <section className="my-2 py-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <img alt="Travel booking" src="https://storage.googleapis.com/nomadicplanner-image-assets/mktg/undraw_travel_booking_6koc.png" className="img-fluid"/>
              </div>

              <div className="col-12 col-md-6">
                <div className="my-3 pt-3 d-none d-md-block"></div>
                <h2>Add Trips</h2>
                <p>
                  If you travel a lot for work then you know it’s hard to keep trips vs home life balanced. Create a calendar just with your trips so you, your friends and family know when/where your gone.
                </p>
              </div>
            </div>
          </section>

          <section className="my-2 py-2">
            <div className="row">
              <div className="col-12 col-md-6 order-12 order-md-1">
                <div className="my-3 pt-3 d-none d-md-block"></div>
                <h2>Match friends’ trips</h2>
                <p>
                  We’ll tell you if a friend is planning a trip there too. Maybe the same event, or maybe something completely different.
                </p>
              </div>

              <div className="col-12 col-md-6 order-1 order-md-12">
                <img alt="Aircraft" src="https://storage.googleapis.com/nomadicplanner-image-assets/mktg/undraw_aircraft_fbvl.png" className="img-fluid"/>
              </div>
            </div>
          </section>

          <section className="my-2 py-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <img alt="Departing" src="https://storage.googleapis.com/nomadicplanner-image-assets/mktg/undraw_departing_lsgy.png" className="img-fluid"/>
              </div>

              <div className="col-12 col-md-6">
                <div className="my-3 pt-3 d-none d-md-block"></div>
                <h2>See artists and events</h2>
                <p>
                  Linking your social media unlocks events tailored exactly for you, based on your liked and followed accounts. Go ahead, add potential trips and see if there is something exciting happening.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>

      <section id="section-sign-up" className="py-5 bg-primary text-white">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12 col-md-5 mt-2">
              <Link className="btn btn-outline-light btn-block mb-4 px-4 py-2" to="/users/login">Get started</Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </LandingStyle>
  )
}

/**
 * Export view
 */

export default Landing
