/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function EntireAgreement() {
  return (
    <>
      <section id="entire-agreement" className="my-4">
        <h4 className="my-3">
          Entire Agreement
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            These are our official terms of use and any conflicting information
            should be clarified by reading these terms.
          </div>
        </div>

        <p id="entire-agreement-p1">
          These Terms are the whole agreement between Nomadic Planner and you
          concerning the Services. They supersede all other communications and
          proposals (whether oral, written, or electronic) between you and Nomadic
          Planner with respect to the Services and govern our future relationship.
          The failure of either you or Nomadic Planner to exercise any right provided
          for in these Terms in any way won't be deemed a waiver of any other rights.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default EntireAgreement
