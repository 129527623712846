/**
 * Dependencies
 */

import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { AnswersField } from '../Grouping/index'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function PollFormDialog(props) {
  const [answer_a, setAnswerA] = useState('')
  const [answer_b, setAnswerB] = useState('')
  const [answer_c, setAnswerC] = useState('')
  const [answer_d, setAnswerD] = useState('')

  async function onSubmitHandler(e) {
    e.preventDefault()

    if (props.pollDescription.length === 0) {
      alert('Polls need a description.')
    } else {
      await firebase.db.collection('polls').add({
        trip_id: props.trip.id,
        event_id: props.pollEvent,
        planner_email: props.userContext.email,
        planner_uid: props.userContext.uid,
        description: props.pollDescription,
        answers: [answer_a, answer_b, answer_c, answer_d],
        counts: [0,0,0,0]
      })

      setAnswerA('')
      setAnswerB('')
      setAnswerC('')
      setAnswerD('')
      props.setPollDescription('')
      props.setOpenPollDialog(false)
      window.location.reload()
    }
  }

  return (
    <Dialog open={props.openPollDialog}
            onClose={props.handleClosePollDialog}
            fullWidth={true}
            aria-labelledby="dialog-poll-dialog">
      <DialogTitle id="form-dialog-title">Poll Form</DialogTitle>
      <DialogContent>
        <TextField autoFocus
            id="input-description"
            label="Description"
            type="description"
            margin="normal"
            value={props.pollDescription}
            onChange={(e) => props.setPollDescription(e.target.value)}
            fullWidth />

        <AnswersField key={1} index={1} value={answer_a} setValue={setAnswerA} />
        <AnswersField key={2} index={2} value={answer_b} setValue={setAnswerB} />
        <AnswersField key={3} index={3} value={answer_c} setValue={setAnswerC} />
        <AnswersField key={4} index={4} value={answer_d} setValue={setAnswerD} />

      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClosePollDialog} color="primary">
          Cancel
        </Button>

        <Button onClick={onSubmitHandler} color="primary">
          Create Poll
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Export component
 */

export default PollFormDialog
