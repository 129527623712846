/**
 * Dependencies
 */

import styled from 'styled-components'

/**
 * Define styles
 */

const TripLinkCardStyle = styled.div`
  :hover {
    background-color: #e5eeff;
  }
`

/**
 * Export styles
 */

export default TripLinkCardStyle
