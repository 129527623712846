/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function NoChildren() {
  return (
    <>
      <section id="no-children" className="my-5">
        <h4 className="my-4">
          Children's Privacy
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            To use the Service you must be an adult (18+).
          </div>
        </div>

        <p id="no-children-p1">
          We do not knowingly collect personal information from anyone under 18 years of age.
        </p>

        <p id="no-children-p2">
          The Nomadic Planner Services are not directed to anyone under the age of 18.
          Anyone under 18 is not permitted to sign up or use the Service
          as stipulated within our Terms of Service
          <a href="https://www.nomadicplanner.com/terms">www.nomadicplanner.com/terms</a>.
        </p>

        <p id="no-children-p3">
          If we become aware that we have unknowingly collected personal information
          from anyone under the age of 18, we will make reasonable efforts to delete
          such information from our database.
        </p>

        <p id="no-children-p4">
          If you are a parent or guardian of a child under the age of 18, and you
          believe that your child has provided us with personal information, please
          contact us via email at <a href="mailto:privacy@nomadicplanner.com">privacy@nomadicplanner.com</a>.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default NoChildren
