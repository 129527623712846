/**
 * Dependencies
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { AdminStyle } from './styles/index'
import {
  HomeNavbar,
  HomeDashboardLink,
} from '../../components/Home/index'

/**
 * Define view
 */

function Admin() {
  return (
    <AdminStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      <main className="bg-white shadow pt-2 pb-5">
        <HomeDashboardLink />

        <div className="container">
          <div className="row mt-5">
            <div className="col-12">
              <h3>Admin Dashboard</h3>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <Link to="/admin/users" className="btn btn-lg btn-block btn-secondary m-2">Users</Link>
            </div>

            <div className="col-4">
              <Link to="/admin/trips" className="btn btn-lg btn-block btn-secondary m-2">Trips</Link>
            </div>

            <div className="col-4">
              <Link to="/admin/events" className="btn btn-lg btn-block btn-secondary m-2">Events</Link>
            </div>
          </div>

        </div>
      </main>
    </AdminStyle>
  )
}

/**
 * Export view
 */

export default Admin
