/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Communications() {
  return (
    <>
      <section id="communications" className="my-5">
        <h4 className="my-3">
          Communications
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We only use email and other electronic means to stay in touch with our Users.
          </div>
        </div>

        <p id="communications-p1">
          By registering for an Nomadic Planner account, you agree that such registration
          constitutes your electronic signature, and you consent to receive
          electronic notices from Nomadic Planner ("Notices"), including those required by law.
          You also agree that your electronic consent will have the same legal effect
          as a physical signature.
        </p>

        <p id="communications-p2">
          For contractual purposes, you consent to receive communications from us
          in an electronic form via the email address you have submitted or via the
          Service. You agree that all Terms of Service, agreements, notices,
          disclosures, and other communications that we provide to you electronically
          satisfy any legal requirement that those communications would satisfy if
          they were on paper.
        </p>

        <p id="communications-p3">
          We may provide you with other marketing or business-related information,
          including information about Service updates or changes, via email or via
          our <a href="http://blog.nomadicplanner.com">blog.nomadicplanner.com</a>.
        </p>

        <p id="communications-p4">
          Communications made through email or Nomadic Planner Support's messaging system will
          not constitute legal notice to Nomadic Planner or any of its officers, employees,
          agents or representatives in any situation where notice to Nomadic Planner is
          required by contract or any law or regulation. Legal notice to Nomadic Planner must
          be in writing and served on Nomadic Planner's legal agent.
        </p>

        <p id="communications-p5">
          Nomadic Planner only offers support via email, in-Service communications, and
          electronic messages. We do not offer telephone support.
        </p>

        <p id="communications-p6">
          Usage of the Services require agreement to electronic delivery of Notices.
          However, you may choose to withdraw your consent to receive Notices
          electronically by terminating your Nomadic Planner account.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Communications
