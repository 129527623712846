/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function Disputes() {
  return (
    <>
      <section id="disputes" className="my-5">
        <h4 className="my-3">
          Disputes
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            We're located in Wisconsin, and any disputes with us have to be handled in
            Wisconsin under <strike>Wisconsin State law</strike>.
          </div>
        </div>

        <p id="disputes-p1">
          You agree that prior to filing any claim against Nomadic Planner relating to or
          arising out of these Terms you will first contact us at
          <a href="mailto:dispute@nomadicplanner.com">dispute@nomadicplanner.com</a> to provide us
          with an effort to resolve the issue in an informal manner.
        </p>

        <p id="disputes-p2">
          If such dispute is not resolved to our mutual satisfaction within 30 days
          of submission, then either party may bring a formal claim, which claim
          shall be resolved through final and binding arbitration administered by
          the American Arbitration Association ("AAA").
        </p>

        <p id="disputes-p3">
          The AAA will administer arbitration proceedings under its Commercial
          Arbitration Rules and the Supplementary Procedures for Consumer Related
          Disputes. Arbitration will be held in Madison, WI or in any other
          location we agree to. The AAA rules will govern payment of all arbitration
          fees. Nomadic Planner will not seek its attorneys' fees and costs in arbitration
          unless the arbitrator determines that your claim is frivolous.
        </p>

        <p id="disputes-p4">
          Either you or Nomadic Planner may assert a claim, if it qualifies, in small claims
          court in Madison, WI. Nothing in this Section shall be deemed as preventing
          either party from seeking injunctive or other equitable relief from the
          courts as necessary to stop unauthorized use or abuse of the Service or
          intellectual property infringement (for example, involving trademark,
          trade secrets, copyright or patent rights) without first engaging in
          arbitration or the informal dispute resolution process.
        </p>

        <p id="disputes-p5">
          To the fullest extent permitted by law, each of the parties agrees that any
          proceeding, whether in arbitration or in court, will be conducted only on
          an individual basis and not in a class, consolidated, or representative
          action. If for any reason a claim or dispute proceeds in court rather than
          through arbitration, each party knowingly and irrevocably waives any right
          to trial by jury in any action, proceeding or counterclaim arising out of
          or relating to this Agreement or any of the transactions contemplated between
          the parties.
        </p>

        <p id="disputes-p6">
          In the event that the agreement to arbitrate is found not to apply to your
          claim, then you and Nomadic Planner agree that any judicial proceedings will be
          brought in the federal or state courts in Madison, WI and both parties
          consent to venue and personal jurisdiction there.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default Disputes
