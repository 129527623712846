/**
 * Dependencies
 */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from './helpers/index'
import {
  Login,
  Notifications,
  ConfirmEmail,
  GettingStarted,
  Settings,
  UsersSearch,
  DeactivateAccount,
  Suspended,
  SignOut,
} from '../views/users/index'
import { ErrorBoundary } from '../views/index'
import uuid from 'uuid'

/**
 * Define router
 */

const UsersRouter = [
  <Route key={uuid.v4()} exact path='/users' render={() => <Redirect to='/' />} />,
  <Route key={uuid.v4()} path='/users/signin' render={() => <Redirect to='/users/login' />} />,
  <Route key={uuid.v4()} path='/users/signup' render={() => <Redirect to='/users/login' />} />,
  <Route key={uuid.v4()} path='/users/login' render={(props) => <Login {...props} />} />,
  <Route key={uuid.v4()} path='/users/register' render={(props) => <Login {...props} />} />,
  <Route key={uuid.v4()} path='/users/confirm-email' render={(props) => <ConfirmEmail {...props} />} />,
  <Route key={uuid.v4()} path='/users/suspended' render={(props) => <Suspended {...props} />} />,
  <Route key={uuid.v4()} path='/users/signout' render={(props) => <SignOut {...props} />} />,
  <PrivateRoute key={uuid.v4()} path='/users/getting-started' component={GettingStarted} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/users/notifications' component={Notifications} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/users/settings' component={Settings} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/users/search' component={UsersSearch} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/users/deactivate-account' component={DeactivateAccount} errorBoundary={ErrorBoundary} />,
]

/**
 * Export router
 */

export default UsersRouter
