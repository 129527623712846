/**
 * Dependencies
 */

import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import leaflet from 'leaflet'
import axios from 'axios'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Redirect, Link } from 'react-router-dom'
import { TripsShowStyle } from './styles'
import { UserContext } from '../../contexts'
import { HomeNavbar, BackToLink } from '../../components/Home'
import { EventsList } from '../../components/Events'
import { PollFormDialog } from '../../components/Polls'
import { InviteEmailDialog, InviteFriendDialog } from '../../components/Trips'
import firebase from '../../helpers/firebase'

/**
 * Define component
 */

function TripsShow(props) {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [emailToInvite, setEmailToInvite] = useState('')
  const [trip, setTrip] = useState({})
  const [events, setEvents] = useState([])
  const [trip_invites, setTripInvites] = useState([])
  const [friendSuggestions, setFriendSuggestions] = useState([])
  const [friends, setFriends] = useState([])
  const [pollEvent, setPollEvent] = useState('')
  const [isInvitedTrip, setIsInvitedTrip] = useState(false)
  const [openInviteEmail, setOpenInviteEmail] = useState(false)
  const [openInviteFriend, setOpenInviteFriend] = useState(false)
  const [openPollDialog, setOpenPollDialog] = useState(false)
  const [pollDescription, setPollDescription] = useState('')

  const handleClickOpenEmailInvite = () => {
    setOpenInviteEmail(true)
  }
  const handleCloseInviteEmail = () => {
    setEmailToInvite('')
    setOpenInviteEmail(false)
  }

  const handleClickOpenInviteFriend = () => {
    setOpenInviteFriend(true)
  }
  const handleCloseInviteFriend = () => {
    setEmailToInvite('')
    setOpenInviteFriend(false)
  }

  const handleClosePollDialog = () => {
    setPollDescription('')
    setOpenPollDialog(false)
  }

  useEffect(() => {
    async function fetchTrip() {
      if (!props.match.params.id) {
        return setTrip(false)
      }

      const tripRef = await firebase.db.collection('trips').doc(props.match.params.id)
      const tripSnapshot = await tripRef.get()
      const this_trip = Object.assign({}, tripSnapshot.data(), {id: tripSnapshot.id})

      const tripInvitesRef = await firebase.db.collection('trip_invites')
        .where('trip_id', '==', tripSnapshot.id)
        .where('receiver_email', '==', userContext.email)
        .where('declined_at', '==', null)
      const tripInvitesSnapshot = await tripInvitesRef.get()
      let invites_list = tripInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
      invites_list = invites_list.filter(invite => invite.accepted_at != null)

      if (tripSnapshot.exists) {
        setTrip(this_trip)

        // Check planner or invited else redirect to home page.
        if (this_trip.planner_uid !== userContext.uid && invites_list.length === 0) {
          return window.location = '/home'
        }

        if (invites_list.length > 0) {
          setIsInvitedTrip(true)
        }

        const eventsRef = await firebase.db.collection('events')
          .where('trip_id', '==', tripSnapshot.id)
          .orderBy('start_at', 'desc')
          .orderBy('end_at', 'desc')
        const eventsSnapshot = await eventsRef.get()
        let event_list = eventsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

        const pollsRef = await firebase.db.collection('polls')
          .where('trip_id', '==', tripSnapshot.id)
        const pollsSnapshot = await pollsRef.get()
        const poll_list = pollsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

        const tripInvitesRef = await firebase.db.collection('trip_invites')
          .where('trip_id', '==', tripSnapshot.id)
        const tripInvitesSnapshot = await tripInvitesRef.get()
        let trip_invites_list = tripInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))

        const friendInvitesRef = await firebase.db.collection('friend_requests')
          .where('sender_uid', '==', userContext.uid)
          .where('declined_at', '==', null)
        const friendInvitesSnapshot = await friendInvitesRef.get()
        let friendInvites = friendInvitesSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
        friendInvites = friendInvites.filter(invite => invite.accepted_at != null)

        let travelers_emails = trip_invites_list.map(invite => invite.receiver_email)
        friendInvites = friendInvites.filter(invite => !travelers_emails.includes(invite.receiver_email))
        friendInvites = friendInvites.slice(0,3)
        setFriendSuggestions(friendInvites)

        trip_invites_list = trip_invites_list.filter(invite => invite.accepted_at != null)
        setTripInvites(trip_invites_list)

        event_list = event_list.map(event => {
          event.polls = poll_list.filter(poll => poll.event_id === event.id)
          return event
        })

        setEvents(event_list)
        if (userContext) setIsLoading(false)

        /**
         * Fetching friends not yet invited
         */

        async function fetchFriendsNotYetInvited() {
          /**
           * Fetch friend requests
           */

          const requestsRef = await firebase.db.collection('friend_requests')
            .where('sender_uid', '==', userContext.uid)
            .where('declined_at', '==', null)
          const requestsSnapshot = await requestsRef.get()

          let objRequests = requestsSnapshot.docs.map(doc => Object.assign({}, doc.data(), {id: doc.id}))
          objRequests = objRequests.filter(request => request.accepted_at != null)
          const friends = objRequests.map(request => request.receiver_email)
          setFriends(friends)
        }
        fetchFriendsNotYetInvited()
      } else {
        setTrip(false)
      }
    }

    fetchTrip()
  }, [])

  useEffect(() => {
    function initMap() {
      let init_map = leaflet.map('map', {
        attributionControl: false,
        zoomControl: false,
      }).setView([trip.coord_lat, trip.coord_lng], 11)

      leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(init_map)
    }

    if (!isLoading) initMap()
  }, [isLoading])

  if (!trip) return <Redirect to="/home" />

  function deleteTrip(event) {
    event.preventDefault()

    const yes = window.confirm('Are you sure? This permanently deletes this Trip.')

    if (yes) {
      firebase.db.collection('trips').doc(trip.id).delete()
      setTrip(false)
    }
  }

  async function uninviteFriend(event, invite_id) {
    event.preventDefault()

    const yes = window.confirm('Are you sure? This permanently uninvites this friend.')

    if (yes) {
      await firebase.db.collection('trip_invites').doc(invite_id).delete()
      window.location.reload()
    }
  }

  function sendEmailInvite(e) {
    e.preventDefault()

    setEmailToInvite('')
    setOpenInviteEmail(false)
    setOpenInviteFriend(false)

    axios.post('https://us-central1-knowmadic-97976.cloudfunctions.net/api/emails/invite', {
      trip_id: props.match.params.id,
      trip_title: trip.title,
      email_from: userContext.email,
      email_to: emailToInvite,
      created_at: moment(new Date).format('YYYY-MM-DD HH:MM'),
      trip_description: trip.description,
      trip_start_at: trip.start_at,
      trip_end_at: trip.end_at,
      trip_coord_lat: trip.coord_lat,
      trip_coord_lng: trip.coord_lng,
    }, {
      "Content-Type": "application/json",
    })
  }

  function sendSuggestedInvite(e, friend_email) {
    e.preventDefault()

    setEmailToInvite('')
    setOpenInviteEmail(false)
    setOpenInviteFriend(false)

    axios.post('https://us-central1-knowmadic-97976.cloudfunctions.net/api/emails/invite', {
      trip_id: props.match.params.id,
      trip_title: trip.title,
      email_from: userContext.email,
      email_to: friend_email,
      created_at: moment(new Date).format('YYYY-MM-DD HH:MM'),
      trip_description: trip.description,
      trip_start_at: trip.start_at,
      trip_end_at: trip.end_at,
      trip_coord_lat: trip.coord_lat,
      trip_coord_lng: trip.coord_lng,
    }, {
      "Content-Type": "application/json",
    })
    window.location.reload()
  }

  return (
    <TripsShowStyle>
      <header className="bg-light">
        <HomeNavbar />
      </header>

      {isLoading ? <LinearProgress /> : null}

      <main className="bg-white shadow py-4">
        <div className="container">
          <BackToLink url="/home" text="Home" />
        </div>
      </main>

      {isLoading ? null :
        <div className="container">
          <section id="section-trips-show-form">
            <div className="row mt-3 justify-content-center">
              <div className="col-12 col-lg-10">

                <div className="card mb-5 anim-fade-up shadow-lg">
                  <div className="card-block p-3">

                    <div className="row">
                      <div className="col-12">
                        <h3 className="mt-2 text-center d-none d-md-block">{trip.title}</h3>
                        <h5 className="mt-2 text-center d-block d-md-none">{trip.title}</h5>

                        <hr/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-8">
                        <div className="row mb-3">
                          <div className="col-12">
                            <p>
                              {trip.description}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <p>
                              <strong>Starts At:</strong><br/> {(trip && trip.start_at) ? moment(trip.start_at).format('YYYY-MM-DD hh:mm') : null}
                            </p>
                          </div>
                          <div className="col-6">
                            <p>
                              <strong>Ends At:</strong><br/> {(trip && trip.end_at) ? moment(trip.end_at).format('YYYY-MM-DD hh:mm') : null}
                            </p>
                          </div>

                          <div className="col-12">
                            {(trip && trip.links) ? trip.links.map((link, index) => <div key={index}><a href={link} target="_blank">{link}</a><br/></div>) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <div id="map" className="bg-dark rounded" style={{"height": "200px", "width": "100%"}}></div>
                      </div>
                    </div>

                    {isInvitedTrip ? null :
                      <div className="row">
                        <div className="col-12 mt-3 d-flex align-items-center">
                          <h4>Invite friends:</h4>

                          <button className="btn btn-sm btn-primary mx-2"
                                  onClick={handleClickOpenEmailInvite}
                                  style={{"minWidth": "50px", "minHeight": "30px"}}>
                            <i className="fas fa-envelope"></i>
                          </button>

                          <button className="btn btn-sm btn-primary mr-2"
                                  onClick={handleClickOpenInviteFriend}
                                  style={{"minWidth": "50px", "minHeight": "30px"}}>
                            <i className="fas fa-users"></i>
                          </button>
                        </div>
                      </div>
                    }

                    <div className="row mt-4 justify-content-start">
                      <div className="col-12">
                        <h5>Suggested Travelers</h5>
                        <ul>
                          {trip_invites.map((invite, index) => {
                            return (
                              <li key={index}>
                                {invite.receiver_email}
                                {isInvitedTrip ? null :
                                  <small><button onClick={e => uninviteFriend(e, invite.id)}
                                          className="btn btn-link btn-sm text-muted">x</button>
                                  </small>
                                }
                              </li>
                            )
                          })}

                          {friendSuggestions.map((invite, index) => {
                            return (
                              <li key={index + trip_invites.length + 1}>
                                {invite.receiver_email}
                                <small><button onClick={e => sendSuggestedInvite(e, invite.receiver_email)}
                                        className="btn btn-sm btn-success ml-2">Invite</button>
                                </small>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>

                    <hr/>

                    {trip.id ?
                      <div className="row mt-3">
                        <div className="col-12">
                          {isInvitedTrip ? null :
                            <Link to={`/trips/${trip.id}/events/new`} className="btn btn-md btn-primary">
                              Add Event
                            </Link>
                          }
                        </div>
                      </div>
                      : null
                    }

                    <div className="row">
                      <div className="col-12">
                        <EventsList trip={trip} events={events} setEvents={setEvents}
                                    setOpenPollDialog={setOpenPollDialog}
                                    setPollEvent={setPollEvent}
                                    isInvitedTrip={isInvitedTrip}
                                    {...props} />
                      </div>
                    </div>

                    {isInvitedTrip ? null :
                      <div className="row">
                        <div className="col-12 pt-3 text-right">
                          <button onClick={deleteTrip} className="btn btn-sm btn-link text-danger"><i className="fa fa-trash"></i></button>
                        </div>
                      </div>
                    }

                  </div>
                </div>

              </div>
            </div>
          </section>

          <PollFormDialog openPollDialog={openPollDialog}
                          handleClosePollDialog={handleClosePollDialog}
                          pollDescription={pollDescription}
                          setPollDescription={setPollDescription}
                          trip={trip}
                          userContext={userContext}
                          pollEvent={pollEvent}
                          setOpenPollDialog={setOpenPollDialog} />

          <InviteEmailDialog open={openInviteEmail}
                             onClose={handleCloseInviteEmail}
                             emailToInvite={emailToInvite}
                             setEmailToInvite={setEmailToInvite}
                             sendEmailInvite={sendEmailInvite} />

          <InviteFriendDialog open={openInviteFriend}
                             onClose={handleCloseInviteFriend}
                             emailToInvite={emailToInvite}
                             friends={friends}
                             setEmailToInvite={setEmailToInvite}
                             sendEmailInvite={sendEmailInvite} />

        </div>
      }
    </TripsShowStyle>
  )
}

/**
 * Export component
 */

export default TripsShow
