/**
 * Dependencies
 */

import React from 'react'

/**
 * Define component
 */

function InfoSharing() {
  return (
    <>
      <section id="info-sharing" className="my-5">
        <h4 className="my-4">
          How This Information Is Shared
        </h4>

        <div className="card text-white bg-primary my-3">
          <div className="card-block p-3">
            The Service enables social traveling.
          </div>
        </div>

        <p id="info-sharing-p1">
          We may employ third party companies or individuals to process personal
          information on our behalf based on our instructions and in compliance with
          this Privacy Policy. For example, we share credit card information with our
          payment processor Stripe used to handle and store payment information.
          In addition, some of the information we request may be collected by third
          party providers on our behalf. For more information about the providers we
          use, please reference our support documentation at
          <a href="mailto:support@nomadicplanner.com">support@nomadicplanner.com</a>.
        </p>

        <p id="info-sharing-p2">
          We do reserve the right to disclose personal information when we believe
          that doing so is reasonably necessary to comply with the law or law
          enforcement, to prevent fraud or abuse, or to protect Nomadic Planner's legal rights.
        </p>
      </section>
    </>
  )
}

/**
 * Export component
 */

export default InfoSharing
