/**
 * Dependencies
 */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from './helpers/index'
import { TripsArchive, TripsForm, TripsShow } from '../views/trips/index'
import { ErrorBoundary, EventsForm, EventsShow } from '../views/index'
import uuid from 'uuid'

/**
 * Define router
 */

const TripsRouter = [
  <Route key={uuid.v4()} exact path='/trips' render={() => <Redirect to='/home' />} />,
  <PrivateRoute key={uuid.v4()} path='/trips/archive' component={TripsArchive} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/trips/new' component={TripsForm} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} exact path='/trips/:id' component={TripsShow} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/trips/:id/events/new' component={EventsForm} errorBoundary={ErrorBoundary} />,
  <PrivateRoute key={uuid.v4()} path='/trips/:trip_id/events/:id' component={EventsShow} errorBoundary={ErrorBoundary} />,
]

/**
 * Export router
 */

export default TripsRouter
