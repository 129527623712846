/**
 * Dependencies
 */

import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost'

/**
 * Constants
 */

const graphql_uri = (process.env.NODE_ENV === 'production') ?
  'https://us-central1-knowmadic-97976.cloudfunctions.net/api' : 'http://localhost:5000'

/**
 * Define client
 */

const client = new ApolloClient({
  link: new HttpLink({ uri: graphql_uri }),
  cache: new InMemoryCache(),
})

/**
 * Export client
 */

export default client
